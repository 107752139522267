export const checkEmail = (e) => {
  const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (e != '' && e.match(validRegex)) {
    return true;
  }
  return false;
};

export const capitalizarPrimeraLetra = (str) => {
  if (str === null || str === '') {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};
