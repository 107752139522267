import { combineReducers } from 'redux';
import Pool from './pool';

// Reducers

const reducers = combineReducers({
  bazooit: Pool,
});

export default reducers;
