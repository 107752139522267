import React from 'react';
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import { PropTypes } from 'prop-types';

export function FieldList({
  field, onChange, indexField, indexGroup,
}) {
  return (
    <Box sx={{ mb: 0, mt: 2 }}>
      <FormControl fullWidth size="small">
        <InputLabel id="simple-select-01-add-label">{field.label}</InputLabel>
        <Select
          labelId="simple-select-01-add-label"
          id="simple-select-01-add"
          label={field.label}
          value={field.value}
          onChange={(e) => { onChange(indexField, e.target.value, indexGroup); }}
          error={field.required && (field.value === '' || field.value === undefined || field.value === null)}
        >
          {field.options.length > 0 && field.options.map((itemActividad) => (

            <MenuItem
              key={itemActividad.id}
              value={itemActividad.id}
            >
              {itemActividad.label}
            </MenuItem>

          ))}

        </Select>
        {field.required && (field.value === '' || field.value === undefined || field.value === null) && (
          <FormHelperText error>{`${field.required ? '* Campo Obligatorio' : ''}`}</FormHelperText>
        )}

      </FormControl>
    </Box>
  );
}

FieldList.propTypes = {
  field: PropTypes.objectOf.isRequired,
  onChange: PropTypes.func.isRequired,
  indexField: PropTypes.number.isRequired,
  indexGroup: PropTypes.number.isRequired,
};

export default FieldList;
