const initState = {
  connection: false,
  notification: {
    estatus: false,
    tipo: 'info', // error //warning // info // success
    mensaje: '',
  },
};

export default initState;
