const initState = {
  id: null,
  rut: null,
  company: null,
  connection: false,
  error: false,
  message: null,
};

export default initState;
