import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Divider,
  Box,
  ButtonGroup,
  CardContent,
  Alert,
  Card,
  CardHeader,
  CardActions,
  Grid,
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Avatar from '@mui/material/Avatar';
import {
  red,
} from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import { cardsActionsSaga } from '../../../../infrastructure/services/cards';
import CreateForms from '../../organismos/forms';
import FieldDowload from '../fieldDowload';
import ComentsView from '../ComentsView';

function ArtefactoMostrar({
  artefactoMostar,
  columIdMostar,
  artefactoMostarData,
  handleCloseMostrar,
  idWorkflowSelected,
  cardSelected,
}) {
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState(true);
  const [newFields, setNewFields] = useState(null);
  const [enabledForm, setEnabledForm] = useState(null);

  const findData = () => {
    if (artefactoMostarData) {
      const query = `${artefactoMostarData}/?columnId=${columIdMostar}`;
      dispatch(cardsActionsSaga.activeCardsGetDetail({ query }));
    }
  };

  useEffect(() => {
    findData();
  }, [artefactoMostarData]);

  const { cardDetail, result, connection } = useSelector((store) => store.bazooit.cards.cardDetail);
  const { cardHasBeenUpdated } = useSelector((store) => store.bazooit.cards);

  const rojo = red[500];

  const getFields = (get) => {
    setNewFields(get);
  };

  const getEnabledForm = (flag) => {
    setEnabledForm(flag);
  };
  const buscarDatos = () => {
    const buildetail = cardDetail != null && cardDetail.groups.map((group) => (
      <Box key={group}>
        <Typography sx={{ pl: 0 }} variant="h6" color="text.secondary">
          <strong>{group.title}</strong>
        </Typography>
        {
          group.fields.map((field) => (
            <Typography key={field.id} sx={{ pl: 0 }} variant="body2" color="text.secondary">
              <strong>{field.label}</strong>
              :
              {field.kind === 'file' && <FieldDowload value={field.value} url={field.url} />}
              {field.kind === 'list' && field.options.filter((item) => item.id === field.value)[0].label}
              {field.kind !== 'file' && field.kind !== 'list' && field.value}
            </Typography>
          ))
        }
        <br />
      </Box>
    ));

    return buildetail;
  };

  const handlerChabgeVisibility = () => {
    const temp = !visibility;
    setVisibility(temp);
  };

  const handlerActualizar = () => {
    let data = {};
    for (const a in newFields.groups) {
      for (const b in newFields.groups[a].fields) {
        if (newFields.groups[a].fields[b].kind !== 'file') {
          data = { ...data, [newFields.groups[a].fields[b].id]: newFields.groups[a].fields[b].value };
        } else {
          data = { ...data, [newFields.groups[a].fields[b].id]: newFields.groups[a].fields[b].file };
        }
      }
    }

    dispatch(cardsActionsSaga.activeCardsUpdateData(
      {
        cards: {
          workflowId: idWorkflowSelected,
          columnId: columIdMostar,
          ...data,
        },
        idCard: artefactoMostarData,
      },
    ));
    handleCloseMostrar();
  };

  const handlerCheckEdit = () => {
    dispatch(cardsActionsSaga.activeCardHasBeenUpdated({
      cardId: artefactoMostarData,
      updatedAt: newFields.updatedAt,
    }));
    // handleCloseMostrar();
  };
  useEffect(() => {
    if (cardHasBeenUpdated.result) { handlerActualizar(); }
  }, [cardHasBeenUpdated]);

  return (
    <Dialog
      disableEscapeKeyDown
      open={artefactoMostar}
      onClose={handleCloseMostrar}
      fullWidth="md"
      maxWidth="md"
    >
      <DialogTitle sx={{ lineHeight: 1.2 }}>
        {!cardHasBeenUpdated.checking && (
          <CardHeader
            avatar={(
              <Avatar
                sx={{
                  bgcolor: rojo, width: 30, height: 30, fontSize: '15px',
                }}
                aria-label="recipe"
              >
                {cardSelected.visibleFieldsValues[0][0].toUpperCase()}
              </Avatar>
            )}

            title={cardSelected.visibleFieldsValues[0]}
            sx={{ p: 0, pb: 0 }}
          />
          /*  */
        )}
        {
          cardHasBeenUpdated.checking
          && !cardHasBeenUpdated.result
          && !cardHasBeenUpdated.connection && (
            <Typography sx={{ pb: 0, mb: 0 }} variant="h5" component="p">
              {result ? 'Confirmar cambios' : <Skeleton variant="rectangular" width={300} height={30} />}
            </Typography>
          )
        }
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => handleCloseMostrar()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      {' '}
      {cardHasBeenUpdated.checking && !cardHasBeenUpdated.result
        ? (
          <>
            <DialogContent sx={{ pl: 0, pr: 0 }}>
              <Box sx={{ pl: 4, pr: 4 }}>
                <Alert severity="error">{cardHasBeenUpdated.message}</Alert>
              </Box>
            </DialogContent>
            <CardActions>
              <ButtonGroup sx={{ pt: 0, zIndex: 5 }} fullWidth variant="contained">
                <Button onClick={(e) => { handlerActualizar(e); }}>
                  Si, Confirmar
                </Button>
                <Button onClick={(e) => { handleCloseMostrar(e); }}>
                  Descartar Cambios
                </Button>
              </ButtonGroup>
            </CardActions>
          </>
        ) : (
          <DialogContent sx={{ p: 0, backgroundColor: '#ededed' }}>
            <Grid sx={{ flexGrow: 1, p: 0.2, pr: 0.5 }} container>
              <Grid xs={12} sm={12} md={8} sx={{ p: 0.5 }}>
                <Card sx={{ mb: 0 }}>
                  <CardHeader
                    action={(
                      <IconButton aria-label="settings" onClick={handlerChabgeVisibility}>
                        {visibility ? <EditIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
                      </IconButton>
                    )}
                    subheader={(
                      <Typography sx={{ p: 0, fontSize: 15 }} variant="h6" color="text.secondary">Detalle</Typography>
                    )}
                  />
                  <Divider />
                  <CardContent sx={{ p: visibility ? 2 : 0 }}>
                    {result && !visibility && (
                    <CreateForms
                      fields={cardDetail}
                      getFields={getFields}
                      getEnabledForm={getEnabledForm}
                    />
                    )}
                    {result && connection && visibility && (
                      <Box sx={{ pr: 0, pl: 0 }}>
                        <Typography>
                          <Box sx={{ pb: 0 }}>
                            {buscarDatos(cardDetail)}
                          </Box>
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                  {!visibility
                    && (
                      <CardActions>
                        <ButtonGroup sx={{ pt: 0, zIndex: 5 }} fullWidth variant="contained">

                          <Button color="success" disabled={!enabledForm} onClick={() => handlerCheckEdit()}>
                            Actualizar
                          </Button>
                        </ButtonGroup>
                      </CardActions>
                    )}
                </Card>
              </Grid>
              <Grid xs={12} sm={12} md={4} sx={{ p: 0.5 }}>
                <Card sx={{ maxHeight: 'dis' }}>
                  <Divider />
                  <CardContent sx={{ p: 0 }}>
                    <ComentsView
                      handlerShow={false}
                      show={false}
                      cardSelected={cardSelected}
                      integrate
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
        )}
    </Dialog>

  );
}

export default ArtefactoMostrar;
