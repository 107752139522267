import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSignal, useComputed } from '@preact/signals-react';
import { useHistory } from 'react-router-dom';
import { useSignals } from '@preact/signals-react/runtime';
// import { computed } from '@preact/signals-react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { sesionActions } from '../../../../application/actions/session';
import { checkEmail } from '../../../../helpers';
import bazooitLogo from '../../../assets/img/bazooitLogo.svg';
import { companyActionsSaga } from '../../../../infrastructure/services/company';

import './index.css';

function Login({
  handleLogin,
  statusConnection,
  heandleRecoverPaswoard,
  handlerChangePassword,
  recovery,

}) {
  // This is Mandatory
  useSignals();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSignal('');
  const password = useSignal('');
  const minmunLength = 8;
  const maximunLength = 12;
  const disabled = useComputed(() => !(user.value !== '' && password.value !== '' && password.value.length >= minmunLength && password.value.length <= maximunLength && checkEmail(user.value)));
  const passwordRecoveryChange = useSignal('');
  const passwordRecoveryChangeValid = useSignal(false);
  const passwordRecoveryChangeRepeat = useSignal('');
  const passwordRecoveryChangeYoken = useSignal('');

  const [stateLogin, setStateLogin] = useState(0);

  const [firstName, setFirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [secondLastName, setSecondLastName] = useState('');
  const [lastName, setLastName] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [email, setEmail] = useState('');
  const [newUser, setNewUser] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [idNumberCompany, setIdNumberCompany] = useState('');
  const [company, setCompany] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  const { dataUsuarioRegistrado, dataUsuarioRegistradomensaje } = useSelector((store) => store.bazooit.usuario);
  const { session } = useSelector((store) => store.bazooit);
  const { recoverPassword } = useSelector((store) => store.bazooit.session);
  const { changePassword } = useSelector((store) => store.bazooit.session);
  const connectionCreateCompany = useSelector((store) => store.bazooit.company.connection);
  const { message, error } = useSelector((store) => store.bazooit.company);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const disabledNewUser = useComputed(() => {
    if (!firstName !== '') return true;
    if (!secondName !== '') return true;
    if (!secondLastName !== '') return true;
    if (!lastName !== '') return true;
    if (!idNumber !== '') return true;
    if (!email !== '') return true;
    if (!checkEmail(email)) return true;
    if (!newUser !== '') return true;
    if (!newPassword !== '') return true;
    if (!newUser.length >= 5) return true;
    if (!idNumberCompany !== '') return true;
    if (!company) return true;
    return false;
  });

  useEffect(() => {
    if (dataUsuarioRegistrado && dataUsuarioRegistradomensaje === 'user Crado') {
      setStateLogin(0);
    }
  }, [dataUsuarioRegistrado, dataUsuarioRegistradomensaje]);

  const handleSubmitLogin = () => {
    handleLogin(user.value, password.value);
  };

  const heandleRecover = () => {
    heandleRecoverPaswoard(user.value);
  };

  const handlerReturnLogin = () => {
    setStateLogin(0);
    user.value = '';
    password.value = '';
    dispatch(sesionActions.recoverPassword({
      message: '',
      result: false,
      connection: false,
      error: false,
    }));
  };

  const handlerLogin = () => {
    setShowPassword(false);
    history.push('/');
  };

  const createNewUser = () => {
    const data = {
      company: {
        idNumber: idNumberCompany,
        company,
        abog_patroc: `${firstName} ${secondName} ${lastName} ${secondLastName}`,
        idNumber_abog_patroc: idNumber,
        abog_deman: `${firstName} ${secondName} ${lastName} ${secondLastName}`,
        idNumber_abog_deman: idNumber,
      },
      user: {
        firstName,
        secondName,
        lastName,
        secondLastName,
        email,
        numeroidentidad: idNumber,
        user: newUser,
        password: newPassword,
      },
    };

    dispatch(companyActionsSaga.activecompany(data));
  };

  const validateFormatPassword = () => {
    let validate = true;
    const exp = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/;
    validate = exp.test(passwordRecoveryChange.value);
    if (passwordRecoveryChange.value !== passwordRecoveryChangeRepeat.value && recovery) {
      validate = false;
    }
    passwordRecoveryChangeValid.value = validate;
    console.log(validate);
  };

  useEffect(() => {
    console.log('cambio...', passwordRecoveryChange);
    validateFormatPassword();
  }, [passwordRecoveryChange.value, passwordRecoveryChangeRepeat.value]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tokeChange = urlParams.get('token');
    passwordRecoveryChangeYoken.value = tokeChange;
  });

  const validateMayus = () => {
    let validate = true;
    const exp = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,12}$/;
    validate = exp.test(passwordRecoveryChange.value);
    if (passwordRecoveryChange.value !== passwordRecoveryChangeRepeat.value && recovery) {
      validate = false;
    }
    passwordRecoveryChangeValid.value = validate;
    console.log(validate);
    return validate;
  };

  const expUp = /^(?=.*[A-Z])([^\s]){1,12}$/;
  const expLow = /^(?=.*[a-z])([^\s]){1,12}$/;
  const expSim = /^(?=.*[^\w\d\s:])([^\s]){1,12}$/;
  const expNum = /^(?=.*\d)([^\s]){1,12}$/;

  return (
    <Box
      className="login"
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1, /*
                    width: 'a',
                    height: 228, */
        },
      }}
    >
      <Paper elevation={0}>
        <Box
          component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '30ch' },
          }}
          noValidate
          autoComplete="off"
        >

          <Stack spacing={2}>
            <span className="logo">
              <img className="logoLogin" src={bazooitLogo} alt="svg" />
            </span>
            {!recovery && stateLogin === 0 && (
              <>
                {dataUsuarioRegistrado && (
                  <Alert severity="success">{`El usurio ${firstName} ${secondName} ${secondLastName} ${lastName} fue creado con éxito.`}</Alert>
                )}
                <TextField size="small" id="user" label="Correo" variant="outlined" value={user.value} onChange={(e) => { user.value = e.target.value; }} />
                <FormControl sx={{ m: 1 }} size="small" variant="outlined">
                  <InputLabel htmlFor="password">Clave</InputLabel>
                  <OutlinedInput
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )}
                    label="Clave"
                    value={password.value}
                    onChange={(e) => { password.value = e.target.value; }}
                  />
                </FormControl>

                {session.error && session.message !== '' && <Alert severity="error">{session.message}</Alert>}
                <Button
                  disabled={statusConnection || disabled.value}
                  onClick={() => { handleSubmitLogin(); }}
                  variant="contained"
                  className="button-loging"
                >
                  {!statusConnection ? 'Iniciar' : 'Cargando...'}
                </Button>
                <br />
                <Button
                  onClick={() => { setStateLogin(1); user.value = ''; password.value = ''; }}
                  variant="text"
                  className="button-olvidastes-contrasenia margin-top-10"
                >
                  ¿Olvidaste tu contraseña?
                </Button>
                <p className="textAlingCenter margin-top-0"> - o -</p>
                <Button
                  onClick={() => { setStateLogin(2); }}
                  variant="contained"
                  className="button-create-cuenta margin-top-15"
                >
                  crear una cuenta
                </Button>
              </>

            )}

            {!recovery && stateLogin === 1 && (
              <>

                <TextField disabled={recoverPassword.result} size="small" id="user" label="email" variant="outlined" value={user.value} onChange={(e) => { user.value = e.target.value; }} />
                <Button
                  disabled={user.value === '' || recoverPassword.result || !checkEmail(user.value)}
                  onClick={() => { heandleRecover(); }}
                  variant="contained"
                  className="button-loging"
                >
                  {!statusConnection ? 'Enviar' : 'Enviando...'}
                </Button>

                {recoverPassword.result && recoverPassword.message !== '' && <Alert severity="success">{recoverPassword.message}</Alert>}

                <Button
                  onClick={() => { handlerReturnLogin(); }}
                  variant="text"
                  className="button-olvidastes-contrasenia capitalize margin-top-10"
                >
                  Regresar al login
                </Button>

              </>

            )}

            {!recovery && stateLogin === 2 && (

              <>
                <p className="textAlingCenter">Para poder registrarte es necesario llenar toda la informacion.</p>

                <p className="textAlingCenter"><strong>Datos de empresa.</strong></p>
                <TextField size="small" id="" label="Nombre de la Empresa" variant="outlined" value={company} onChange={(e) => setCompany(e.target.value)} />
                <TextField size="small" id="" label="idNumber empresa" variant="outlined" value={idNumberCompany} onChange={(e) => setIdNumberCompany(e.target.value)} />

                <p className="textAlingCenter"><strong>Información del user administrador.</strong></p>
                <Grid container padding={0}>
                  <Grid padding={1} paddingLeft={0} xs={6}>
                    <TextField size="small" id="" label="Primer Nombre" variant="outlined" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                  </Grid>
                  <Grid padding={1} paddingRight={0} xs={6}>
                    <TextField size="small" id="" label="Segundo Nombre" variant="outlined" value={secondName} onChange={(e) => setSecondName(e.target.value)} />
                  </Grid>
                  <Grid padding={1} paddingLeft={0} xs={6}>
                    <TextField size="small" id="" label="Apellido paterno" variant="outlined" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                  </Grid>
                  <Grid padding={1} paddingRight={0} xs={6}>
                    <TextField size="small" id="" label="Apellido materno" variant="outlined" value={secondLastName} onChange={(e) => setSecondLastName(e.target.value)} />
                  </Grid>
                </Grid>
                <TextField size="small" id="" label="idNumber" variant="outlined" value={idNumber} onChange={(e) => setIdNumber(e.target.value)} />
                <TextField size="small" id="" label="email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)} helperText={email !== '' && !checkEmail(email) && <p className="textAlingCenter noPaddings noMargings colorRed">email invalido.</p>} />
                <TextField size="small" id="user" label="user" variant="outlined" value={newUser} onChange={(e) => setNewUser(e.target.value)} />
                <TextField type="password" size="small" id="password" label="password" variant="outlined" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                {error && message !== '' && <Alert severity="error">{message}</Alert>}
                <Button
                  disabled={connectionCreateCompany || disabledNewUser.value}
                  onClick={() => { createNewUser(); }}
                  variant="contained"
                  className="button-loging"
                >
                  {!connectionCreateCompany ? 'Registrarme' : 'Cargando...'}
                </Button>
                <Button
                  onClick={() => { setStateLogin(0); }}
                  variant="text"
                  className="button-olvidastes-contrasenia capitalize margin-top-10"
                >
                  Regresar al login
                </Button>
              </>

            )}

            {recovery && (

              <>
                {changePassword.result && (
                  <Alert severity="success">La clave ha sido creada con éxito</Alert>
                )}
                <Divider>
                  {' '}
                  <Chip label="Recuerda crear una clave segura" size="small" />
                </Divider>
                <FormControl sx={{ m: 1 }} size="small" variant="outlined">
                  <InputLabel htmlFor="passwordRecoveryChange">Nueva Clave</InputLabel>
                  <OutlinedInput
                    id="passwordRecoveryChange"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )}
                    label="Nueva Clave"
                    value={passwordRecoveryChange.value}
                    onChange={(e) => { passwordRecoveryChange.value = e.target.value; }}
                    readOnly={changePassword.result}
                  />
                </FormControl>
                <FormControl sx={{ m: 1 }} size="small" variant="outlined">
                  <InputLabel htmlFor="passwordRecoveryChangeRepeat">Repetir nueva clave</InputLabel>
                  <OutlinedInput
                    id="passwordRecoveryChangeRepeat"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )}
                    label="Repetir nueva clave"
                    value={passwordRecoveryChangeRepeat.value}
                    onChange={(e) => { passwordRecoveryChangeRepeat.value = e.target.value; }}
                    readOnly={changePassword.result}
                  />
                </FormControl>
                {changePassword.error && changePassword.message !== '' && <Alert severity="error">{changePassword.message}</Alert>}
                <Divider>
                  {' '}
                  <Chip label="Tu clave debe contener" size="small" />
                </Divider>
                <Typography sx={{ pt: 2, pb: 0, pl: 1 }} variant="p" component="small">
                  <CheckCircleIcon color={expUp.test(passwordRecoveryChange.value) ? 'success' : 'disabled'} sx={{ mb: -0.6, fontSize: 15, pr: 0.2 }} />
                  {' '}
                  Al menos un dígito en mayúscula
                </Typography>
                <Typography
                  sx={{
                    pt: 0, pb: 0, mt: 0, pl: 1,
                  }}
                  variant="p"
                  component="small"
                >
                  <CheckCircleIcon color={expLow.test(passwordRecoveryChange.value) ? 'success' : 'disabled'} sx={{ mb: -0.6, fontSize: 15, pr: 0.2 }} />
                  {' '}
                  Al menos un dígito minúscula
                </Typography>
                <Typography sx={{ pt: 0, pb: 0, pl: 1 }} variant="p" component="small">
                  <CheckCircleIcon color={expNum.test(passwordRecoveryChange.value) ? 'success' : 'disabled'} sx={{ mb: -0.6, fontSize: 15, pr: 0.2 }} />
                  {' '}
                  Al menos un carácter numérico
                </Typography>
                <Typography sx={{ pt: 0, pb: 0, pl: 1 }} variant="p" component="small">
                  <CheckCircleIcon color={expSim.test(passwordRecoveryChange.value) ? 'success' : 'disabled'} sx={{ mb: -0.6, fontSize: 15, pr: 0.2 }} />
                  {' '}
                  Al menos un carácter especial
                </Typography>
                <Typography sx={{ pt: 0, pb: 0, pl: 1 }} variant="p" component="small">
                  <CheckCircleIcon color={passwordRecoveryChange.value.length >= 8 && passwordRecoveryChange.value.length <= 12 ? 'success' : 'disabled'} sx={{ mb: -0.6, fontSize: 15, pr: 0.2 }} />
                  {' '}
                  Entre 8 y 12 caracteres
                </Typography>
                <Typography sx={{ pt: 0, pb: 1.5, pl: 1 }} variant="p" component="small">
                  <CheckCircleIcon color={passwordRecoveryChange.value !== '' && (passwordRecoveryChange.value === passwordRecoveryChangeRepeat.value) ? 'success' : 'disabled'} sx={{ mb: -0.6, fontSize: 15, pr: 0.2 }} />
                  {' '}
                  Repetir y recordar tu clave
                </Typography>
                <Button
                  disabled={statusConnection || !passwordRecoveryChangeValid.value || changePassword.result}
                  onClick={() => { handlerChangePassword(passwordRecoveryChangeYoken.value, passwordRecoveryChange.value); }}
                  variant="contained"
                  className="button-loging"
                >
                  {!statusConnection ? 'Crear clave' : 'Cargando...'}
                </Button>
                <Button
                  onClick={() => { handlerLogin(); }}
                  variant="text"
                  className="button-olvidastes-contrasenia capitalize margin-top-10"
                  disabled={!changePassword.result}
                >
                  Ir al login
                </Button>
              </>
            )}
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
}

export default Login;
