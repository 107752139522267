const initState = {
  cards: {
    cards: [],
    result: false,
    connection: false,
    error: false,
  },
  createCards: {
    result: false,
    connection: false,
    error: false,
  },
  updateCards: {
    result: false,
    connection: false,
    error: false,
  },
  fieldsByColumnId: {
    fields: [],
    result: false,
    connection: false,
    error: false,
  },
  cardDetail: {
    cardDetail: null,
    result: false,
    connection: false,
    error: false,
  },
  cardTimeLive: {
    timeLive: [{
      tipo: 'etapas',
      flujo: 'Nombre Columna A',
      fechaEjecucion: '2017-10-05 12:55:55',
      glosaTipo: 'Inicia actividad',
      observaciones: ' nota del usuario',

    },
    {
      tipo: 'actividad',
      flujo: 'Nombre Columna',
      fechaEjecucion: '2017-10-05 12:55:55',
      glosaTipo: 'Inicia actividad',
      observaciones: ' nota del usuario',

    },
    {
      tipo: 'actividad',
      flujo: 'Nombre Columna',
      fechaEjecucion: '2017-10-05 12:55:55',
      glosaTipo: 'Inicia actividad',
      observaciones: ' nota del usuario',

    },
    {
      tipo: 'actividad',
      flujo: 'Nombre Columna',
      fechaEjecucion: '2017-10-05 12:55:55',
      glosaTipo: 'Inicia actividad',
      observaciones: ' nota del usuario',

    },
    {
      tipo: 'actividad',
      flujo: 'Nombre Columna',
      fechaEjecucion: '2017-10-05 12:55:55',
      glosaTipo: 'Inicia actividad',
      observaciones: ' nota del usuario',

    }, {
      tipo: 'etapas',
      flujo: 'Nombre Columna b',
      fechaEjecucion: '2017-10-05 12:55:55',
      glosaTipo: 'Inicia actividad',
      observaciones: ' nota del usuario',

    },
    {
      tipo: 'actividad',
      flujo: 'Nombre Columna',
      fechaEjecucion: '2017-10-05 12:55:55',
      glosaTipo: 'Inicia actividad',
      observaciones: ' nota del usuario',

    },
    {
      tipo: 'actividad',
      flujo: 'Nombre Columna',
      fechaEjecucion: '2017-10-05 12:55:55',
      glosaTipo: 'Inicia actividad',
      observaciones: ' nota del usuario',

    }],
    result: false,
    connection: false,
    error: false,
  },
  cardHasBeenUpdated: {
    message: '',
    success: false,
    connection: false,
    checking: false,
    error: false,
  },
};

export default initState;
