import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  TextField,
  Button,
  IconButton,
  Divider,
  Paper,
  Box,
  Grid,
  ButtonGroup,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Alert from '@mui/material/Alert';
import { workflowActionsSaga } from '../../../../infrastructure/services/workFlow';
import { flujoActions } from '../../../../application/actions/workFlow';

function WorkFlowCreate({ handStateMenu }) {
  const dispatch = useDispatch();

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1976d2',
      },
    },
  });

  /* VARIABLES DE INTERFAZ */
  const [interfaceState] = useState([
    { id: 1, title: 'Define el workflow' },
    { id: 2, title: 'Crear las columnas del workflow' },
    { id: 4, title: 'Implementar workflow' },
  ]);

  const [userPosition, setUserPosition] = useState({ value: 1 });

  /* VARIABLES DE APLICACION */
  const {
    conexion, create,
  } = useSelector((store) => store.bazooit.workflowStorage.createWorkflow);

  /* VARIABLES DE WORKFLOW */
  const [newWorkflow, setNewWorkflow] = useState({ name: '', description: '', columns: [] });
  const [validateColunsStatus, setValidateColunsStatus] = useState(false);

  const [controlInterface] = useState(['crear', 'editar', 'eliminar']);

  const createOterFlow = () => {
    dispatch(flujoActions.createFlowsReset());
    setUserPosition({ value: 1 });
    setNewWorkflow({ name: '', description: '', columns: [] });
  };

  const onSetcolumnsWorkFlow = (etapa, evento, puntero) => {
    const newWorkflowInstance = newWorkflow.columns;
    switch (evento) {
      case controlInterface[0]:
        newWorkflowInstance.push(etapa);
        break;
      case controlInterface[1]:
        newWorkflowInstance[puntero] = etapa;
        break;
      case controlInterface[2]:
        newWorkflowInstance.splice(puntero, 1);
        break;
      default:
        break;
    }
    setNewWorkflow({ ...newWorkflow, columns: newWorkflowInstance });
  };

  const createFlow = () => {
    dispatch(workflowActionsSaga.activecreateWorkflows(newWorkflow));
  };

  useEffect(() => {
    if (create) { dispatch(workflowActionsSaga.activesearchWorkflows()); }
  }, [create]);

  const [width, setWidth] = useState(0);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const optionMessage = () => {
    let messageOpt;

    if (create) {
      messageOpt = (
        <>
          <Typography sx={{ fontWeight: 100 }} variant="h3" component="h3">
            Listo!
          </Typography>
          <Typography sx={{ fontWeight: 100 }} variant="h6" component="h6">
            desde ahora,
          </Typography>
          <Typography sx={{ fontWeight: 100 }} variant="h6" component="h6">
            los usuarios asociados podran generar y gestionar
          </Typography>
          <Typography sx={{ fontWeight: 100 }} variant="h6" component="h6">
            casos de trabajo.
          </Typography>
        </>
      );
    } else if (!create && newWorkflow.columns.length > 0 && newWorkflow.name !== '' && newWorkflow.description !== '' && validateColunsStatus) {
      messageOpt = (
        <>
          <Typography sx={{ fontWeight: 100 }} variant="h3" component="h3">
            Esta todo listo!
          </Typography>
          <Typography sx={{ fontWeight: 100 }} variant="h6" component="h6">
            Luego de implementar el workflow,
          </Typography>
          <Typography sx={{ fontWeight: 100 }} variant="h6" component="h6">
            los usuarios podran generar y gestionar
          </Typography>
          <Typography sx={{ fontWeight: 100 }} variant="h6" component="h6">
            casos de trabajo.
          </Typography>
        </>
      );
    } else if (!create && (newWorkflow.columns.length <= 0 || newWorkflow.name === '' || newWorkflow.description === '' || !validateColunsStatus)) {
      messageOpt = (
        <>
          <Typography sx={{ fontWeight: 100 }} variant="h3" component="h3">
            Aún falta!
          </Typography>
          <Typography sx={{ fontWeight: 100 }} variant="h6" component="h6">
            Es necesario llenar toda la información,
          </Typography>
          <Typography sx={{ fontWeight: 100 }} variant="h6" component="h6">
            para poder implementarlo.
          </Typography>
          <br />

          <Typography sx={{ fontWeight: 500 }} component="div">
            Por favor revisar:
          </Typography>
          <Typography sx={{ fontWeight: 100 }} component="div">
            {newWorkflow.name === '' || newWorkflow.description === '' ? (
              <>
                <br />
                {`Paso 1: ${interfaceState[0].title}`}
                .
              </>
            ) : ''}
            {(newWorkflow.columns.length === 0 || !validateColunsStatus) && (
              <>
                <br />
                {`Paso 2: ${interfaceState[1].title}`}
                {' '}
                {!validateColunsStatus && '(Existen datos pendientes por ingresar)'}
                .
              </>

            )}
          </Typography>
        </>
      );
    }

    return messageOpt;
  };

  const next = () => {
    const x = 1 + userPosition.value;
    setUserPosition({ ...userPosition, value: x });
  };

  const back = () => {
    const x = userPosition.value - 1;
    setUserPosition({ ...userPosition, value: x });
  };

  const validarColumnas = () => {
    const myColumns = newWorkflow.columns;
    const result = myColumns.every((element) => element > '');
    setValidateColunsStatus(result);
  };

  useEffect(() => {
    validarColumnas();
  }, [newWorkflow]);

  const validerPosition = (index) => {
    let estatus = 'disabled';

    if ((index === 1 && userPosition.value === 1) || (index === 1 && userPosition.value > 1)) {
      if (newWorkflow.name === '' || newWorkflow.description === '') {
        estatus = 'error';
      } else {
        estatus = 'success';
      }
    }

    if ((index === 2 && userPosition.value === 2) || (index === 2 && userPosition.value > 2)) {
      if (newWorkflow.columns.length === 0 || !validateColunsStatus) {
        estatus = 'error';
      } else {
        estatus = 'success';
      }
    }

    if (index === 3 && userPosition.value === 3) {
      if (newWorkflow.columns.length === 0 || !validateColunsStatus || newWorkflow.name === '' || newWorkflow.description === '') {
        estatus = 'error';
      } else {
        estatus = 'success';
      }
    }

    return estatus;
  };

  return (
    <Box>
      <Stack sx={{ flexGrow: 1 }}>
        <ThemeProvider theme={darkTheme}>
          <AppBar position="static" color="primary">
            <Grid container sx={{ p: 2, pt: 1.5, pb: 1 }}>
              <Grid sx={{ p: 0 }} item xs={7} sm={8} md={8}>
                <Grid container sx={{ p: 0 }}>
                  <Grid sx={{ p: 0, display: 'flex', flexDirection: 'row' }} item xs={12}>
                    <Typography sx={{ p: 0 }} component="strong">
                      <strong>
                        Crear Workflow
                        {' '}

                      </strong>
                    </Typography>
                    {' '}
                    <Box sx={{ pt: 0.2, pl: 1, opacity: 0.3 }}>
                      <CheckCircleIcon fontSize={userPosition.value === 1 ? 'small' : ''} color={validerPosition(1)} />
                      <CheckCircleIcon fontSize={userPosition.value === 2 ? 'small' : ''} color={validerPosition(2)} />
                      <CheckCircleIcon fontSize={userPosition.value === 3 ? 'small' : ''} color={validerPosition(3)} />
                    </Box>
                  </Grid>
                  <Grid sx={{ p: 0 }} item xs={12}>
                    <Typography sx={{ p: 0 }} component="span">
                      <small>
                        Paso
                        {' '}
                        {userPosition.value}
                        {' '}
                        de
                        {' '}
                        {interfaceState.length}
                        {' '}
                        :
                        {interfaceState[userPosition.value - 1].title}
                      </small>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5} sm={4} md={4} textAlign="end">
                <ButtonGroup disabled={conexion} sx={{ pt: 1 }} size="small" variant="contained" aria-label="outlined primary button group">
                  <Button disabled={!(userPosition.value > 1)} onClick={() => back()} size="small">
                    {width < 600 ? <KeyboardDoubleArrowLeftIcon /> : 'Volver'}
                  </Button>
                  <Button disabled={!(userPosition.value < interfaceState.length)} onClick={() => next()} size="small">
                    {width < 600 ? <KeyboardDoubleArrowRightIcon /> : 'Continuar'}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </AppBar>
        </ThemeProvider>
      </Stack>
      {userPosition.value === 1 && (
      <Grid sx={{ pt: 1, p: 2 }} container>
        <Grid xs={12} sm={12} md={7}>
          <Box
            sx={{
              pt: 1,
            }}
          >
            <Typography variant="h6" component="h6">
              Ingresa la información básica, asociada al workflow.
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={12}>
          <Grid sx={{ pt: 1 }} container>
            <Grid sx={{ mb: 3 }} xs={12} sm={12} md={12}>
              <Typography variant="h5" component="h5">
                <TextField
                  fullWidth
                  multiline
                  size="small"
                  id="outlined-basic"
                  value={newWorkflow.name}
                  onChange={(e) => setNewWorkflow({ ...newWorkflow, name: e.target.value })}
                  label="Nombre del workflow"
                  variant="standard"
                />
              </Typography>
            </Grid>
            <Grid sx={{ mb: 3 }} xs={12} sm={12} md={12}>
              <Typography variant="h5" component="h5">
                <TextField
                  fullWidth
                  multiline
                  size="small"
                  id="outlined-basic"
                  value={newWorkflow.description}
                  onChange={(e) => setNewWorkflow({
                    ...newWorkflow, description: e.target.value,
                  })}
                  label="Descripción del workflow"
                  variant="standard"
                />
              </Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12}>
              <Box sx={{ maxWidth: '100%' }}>

                <ButtonGroup disabled={conexion} size="small" variant="contained" aria-label="outlined primary button group">
                  <Button color="action" onClick={() => handStateMenu(true)} size="small">
                    Abandonar
                  </Button>
                </ButtonGroup>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      )}
      {userPosition.value === 2 && (
      <>
        <Grid container sx={{ p: 2, pb: 0, pt: 1 }}>
          <Grid xs={12} sm={12} md={7}>
            <Grid container>
              <Box
                sx={{
                  maxWidth: '100%',
                  pt: 1,
                }}
              >
                <Typography variant="h6" component="h6">
                  Agrega las columnas necesarias para definir tu workflow.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} md={5}>
            <Box
              component="span"
              display="flex"
              justifyContent="space-between"
              alignItems="flex-center"
              sx={{ p: 1, pr: 0 }}
            >
              <p />
              <ButtonGroup size="small" variant="contained" aria-label="outlined primary button group">
                <Button color="success" size="small" onClick={() => onSetcolumnsWorkFlow('', controlInterface[0], 0)}>
                  Agregar columna
                </Button>
                {/* <Button onClick={(e) => setWorkflowFIXED(e)} size="small">
                      Borrara datos
                    </Button> */}
              </ButtonGroup>
            </Box>
          </Grid>
        </Grid>
        {/* COLUMNAS */}
        <Grid container sx={{ p: 1 }} columns={newWorkflow.columns.length}>
          {!validateColunsStatus && (
            <Grid xs={12} sm={12} md={12} sx={{ p: 0.5 }}>
              <Alert severity="error">Es indispensable definir el nombre de la columna</Alert>
            </Grid>
          )}

          {newWorkflow.columns.map((itemEtapa, index) => (
            <Grid xs={12} sm={1} md={1} sx={{ p: 0.5 }}>
              <Paper>
                <Box
                  component="span"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ p: 1, pb: 0 }}
                >
                  <Typography sx={{ p: 0.8 }} variant="small" component="small">
                    Columna
                    {' '}
                    {index + 1}
                  </Typography>
                  <IconButton size="small" color="primary" aria-label="Eliminar columna" component="label" onClick={() => onSetcolumnsWorkFlow(null, controlInterface[2], index)}>
                    <CancelIcon fontSize="inherit" />
                  </IconButton>
                </Box>
                <Box sx={{ p: 1, pt: 0, pb: 0 }}>
                  <TextField fullWidth sx={{ mb: 2, mt: 2 }} size="small" id={`blokEtapaNombre_${index}`} value={itemEtapa} onChange={(e) => onSetcolumnsWorkFlow(e.target.value, controlInterface[1], index)} label="Nombre de Columna" variant="outlined" />
                </Box>
                <Divider />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </>
      )}
      {userPosition.value === 3 && (
      <Grid sx={{ p: 5 }} xs={12} sm={12} md={7}>
        <Grid container>
          <Box sx={{ maxWidth: '100%', pt: 5 }}>
            {optionMessage()}
          </Box>
        </Grid>
        <Grid container>
          <Box sx={{ maxWidth: '100%', pt: 5 }}>

            <ButtonGroup disabled={conexion} size="small" variant="contained" aria-label="outlined primary button group">
              {!create && (
              <Button
                disabled={
                      !(newWorkflow.columns.length > 0
                        && newWorkflow.name !== '')
                    }
                onClick={(e) => createFlow(e)}
                size="small"
                color="success"
              >
                Implementar
              </Button>
              )}
              <Button onClick={() => handStateMenu(true)} size="small" color="action">
                {create ? 'Buscar workflow creado' : 'Abandonar'}
              </Button>
              {create && (
              <Button onClick={() => createOterFlow()} size="small">
                Crear otro workflow
              </Button>
              )}
            </ButtonGroup>
          </Box>
        </Grid>
      </Grid>
      )}
    </Box>
  );
}

WorkFlowCreate.propTypes = {
  handStateMenu: PropTypes.func.isRequired,
};

export default WorkFlowCreate;
