import { spawn, call, put, takeLatest } from 'redux-saga/effects';
import { createAction } from '@reduxjs/toolkit';
import { flujoActions } from '../../application/actions/workFlow';
import { applicationActions } from '../../application/actions/application';

import api from '../api/index';

const apiRequest = api(process.env.REACT_APP_API_BAZOOIT);

export function* searchWorkflows(e) {
    yield put(flujoActions.connectionFlow({connection:true}));
    try {
        const url = `workflow/columnsByCompany`
        const response = yield call(
            apiRequest.get,
            url
        );

        // Extraigo todos los datos
        const { data, message } = response;

        yield put(flujoActions.createFlow({data,message}));
    } catch (err) {
        yield put(flujoActions.connectionFlow({connection:false}));
        yield put(flujoActions.errorFlow({message:err.message}));
    } finally {
    }
}

export function* buscarTiposworkflows() {
    yield put(flujoActions.connectionFlow({connection:true}));
    try {
        const url = `flujo/tipo`
        const response = yield call(
            apiRequest.get,
            url
        );

        // Extraigo todos los datos
        const { data } = response;
        yield put(flujoActions.createTypesFlow({data}));
    } catch (err) {
        yield put(flujoActions.connectionFlow({connection:false}));
        yield put(flujoActions.errorFlow({message:err.message}));
    } finally {
    }
}

export function* createGrupoworkflows(e) {
    yield put(flujoActions.createGroupsFlowsConnection({connection:true}));
    yield put(applicationActions.applicationConnection({connection:true}));
    try {
        const  params  = e.payload;
        const url = `flujo/grupo`
        const response = yield call(
            apiRequest.post,
            url,
            params
        );

        // Extraigo todos los datos
        const { result, message } = response;
       yield put(flujoActions.createGroupsFlows({create:result}));
    } catch (err) {
        yield put(flujoActions.createGroupsFlowsError({message:err.message, error:err}));
    } finally {
        yield put(flujoActions.createGroupsFlowsConnection({connection:false}));
        yield put(applicationActions.applicationConnection({connection:false}));
    }
}

export function* createWorkflows(e) {
    yield put(flujoActions.createFlowsConnection({connection:true}));
    yield put(applicationActions.applicationConnection({connection:true}));
    try {
        const  params  = e.payload;
        const url = `/workflow/create`
        const response = yield call(
            apiRequest.post,
            url,
            params
        );

        // Extraigo todos los datos 
        const { success } = response;
        yield put(flujoActions.createFlows({create:success}));
    } catch (err) {
        yield put(flujoActions.createFlowsError({message:err.message, error:err}));
    } finally {
        yield put(flujoActions.createFlowsConnection({connection:false}));
       yield put(applicationActions.applicationConnection({connection:false}));
    }
}

export function* mofificarworkflows(e) {
    yield put(flujoActions.modificarFlujoConnection({connection:true}));
    yield put(applicationActions.applicationConnection({connection:true}));
    try {
        const  params  = e.payload;
        const url = `flujo`
        const response = yield call(
            apiRequest.put,
            url,
            params
        );

        // Extraigo todos los datos
        const { result } = response;
       yield put(flujoActions.modificarFlujo({modificado:result}));
    } catch (err) {
        yield put(flujoActions.modificarFlujoError({message:err.message, error: err}));
    } finally {
        yield put(flujoActions.modificarFlujoConnection({connection:false}));
        yield put(applicationActions.applicationConnection({connection:false}));
    }
}


export function* watchFetchFlujo() {
    yield takeLatest(workflowActionsSaga.activesearchWorkflows.type, searchWorkflows);
    yield takeLatest(workflowActionsSaga.activeBuscarTiposworkflows.type, buscarTiposworkflows);
    yield takeLatest(workflowActionsSaga.activecreateWorkflows.type, createWorkflows);
    yield takeLatest(workflowActionsSaga.activecreateGrupoworkflows.type, createGrupoworkflows);
    yield takeLatest(workflowActionsSaga.activemodifyWorkflows.type, mofificarworkflows);
}

export default function* rootSaga() {
    yield spawn(watchFetchFlujo);
}


const activesearchWorkflows = createAction('workflowsaga/activesearchWorkflows');
const activeBuscarTiposworkflows = createAction('workflowsaga/activeBuscarTiposworkflows');
const activecreateWorkflows = createAction('workflowsaga/activecreateWorkflows');
const activecreateGrupoworkflows = createAction('workflowsaga/activecreateGrupoworkflows');
const activemodifyWorkflows = createAction('workflowsaga/activemodifyWorkflows');

export const workflowActionsSaga = {
    activesearchWorkflows,
    activeBuscarTiposworkflows,
    activecreateWorkflows,
    activecreateGrupoworkflows,
    activemodifyWorkflows
};
