import { createSlice } from '@reduxjs/toolkit';
import initState from '../../domain/entities/storageBrowser';



function create(state, action) {
    /* state.data= action.payload;
    state.connection=false;
    state.error= false; */
}

function update(state, action) {

    state.idWorkflowSelected = setDefaultValue(action.payload.idWorkflowSelected);
    state.nameWorkflowSelected = setDefaultValue(action.payload.nameWorkflowSelected);
    state.idArtifactSelected = setDefaultValue(action.payload.idArtifactSelected);
    state.userLocation = setDefaultValue(action.payload.userLocation);
    state.nombreGrupoworkflowselecionado = setDefaultValue(action.payload.nombreGrupoworkflowselecionado);
    state.idWorkflowGroupSelected = setDefaultValue(action.payload.idWorkflowGroupSelected);
    state.artifactWorkflowsSelected = setDefaultValue(action.payload.artifactWorkflowsSelected);
    state.descriptionWorkflowSelected = setDefaultValue(action.payload.descriptionWorkflowSelected); 

    localStorage.setItem('ubicacion', JSON.stringify(state));
}

function setDefaultValue(input){
    let dataReturn = input != undefined && input != null && input != '' ? input:'';
    return dataReturn; 
}

function destruir(state, action) {
    /* state.error= action.payload.error; */
}

/**
 * Conexión
 */
//const initStateSesion = localStorage.length === 0 ? initState:JSON.stringify(localStorage);
const storageBrowserReducer = createSlice({
    name: 'storageBrowser',
    initialState: initState,
    reducers: {
        create,
        update,
        destruir,
    },
});

export const storageBrowserActions = {
    ...storageBrowserReducer.actions,
};

export default storageBrowserReducer.reducer;
