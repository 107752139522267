const initState = {
  createComents: {
    result: false,
    connection: false,
    error: false,
  },
  getComents: {
    coments: [],
    result: false,
    connection: false,
    error: false,
  },
  updateComents: {
    result: false,
    connection: false,
    error: false,
  },
  deleteComents: {
    result: false,
    connection: false,
    error: false,
  },
};

export default initState;
