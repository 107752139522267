import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter, Switch, Route, useHistory, withRouter,
} from 'react-router-dom';
import AppBarBazooit from '../../organismos/AppBarBazooit';
import MenuBazooit from '../../organismos/menuBazooit';

import 'moment/locale/es';
import './index.css';

import { Box } from '@mui/material';
import { BazzoitRouter } from '../../../router/index';
import LinearProgress from '@mui/material/LinearProgress';

function Dashboard() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [stateMenu, setStateMenu] = useState(false);
  const [administradorSelecionado, setAdministradorSelecionado] = useState(0);
  const handStateMenu = (e) => {
    setStateMenu(e);
  };

  const handleCerrarSesion = () => {
    sessionStorage.clear();
    history.push('/');
    window.location.reload(false);
  };

  const handleAgregar = (e) => {};
  const [tituloNuevoFlujo] = useState('crear un nuevo marco de trabajo');
  const [nameWorkflowSelected, setnameWorkflowSelected] = useState('');

  const { connection } = useSelector((store) => store.bazooit.application);
  const { privilegios } = useSelector((store) => store.bazooit.session);

  useEffect(() => {
    if (privilegios.length > 0 && localStorage.getItem('ubicacion')) {
      const dataUbicacion = JSON.parse(localStorage.getItem('ubicacion'));
      let ubicado = false;
      for (let i = 0; privilegios.length > i; ++i) {
        if (privilegios[i].accion === dataUbicacion.userLocation) {
          ubicado = true;
          break;
        }
      }
      ubicado ? history.push(dataUbicacion.userLocation) : history.push(privilegios[0].userLocation);
    }
  }, []);

  return (
    <Box>
      <AppBarBazooit
        handStateMenu={handStateMenu}
        handleCerrarSesion={handleCerrarSesion}
        administradorStatus={administradorSelecionado}
      />
      <MenuBazooit
        stateMenu={stateMenu}
        handStateMenu={handStateMenu}
      />
      {connection && (
      <LinearProgress sx={{
        zIndex: 1200, mt: 6,
      }}
      />
      )}
      <Box className="dasboard">
        <BazzoitRouter
          handStateMenu={handStateMenu}
        />
      </Box>
    </Box>

  );
}

export default Dashboard;
