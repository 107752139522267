const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Connection: 'keep-alive',
};

function _getToken() {
  const user = JSON.parse(sessionStorage.getItem('sesion'));
  const token = user ? user.token : false;
  if (token) headers.Authorization = `Bearer ${token}`;
  return token;
}

function _processResponse(result) {
  return result
    .then((res) => res.json())
    .then(async (res) => {
      const _res = res;

      /* if (!res.data) _res = { error: res.error, data: res.data };
        else if (res.data.error) _res = { error: res.data.error }; */
      return _res;
    })
    .catch((error) => {
      throw Error(error);
    });
}

function _processCreateLink(result, namefile) {
  return result
    .then((response) => response.blob())
    .then(async (blob) => {
      let downloadLink;
      downloadLink = document.createElement('a');
      document.body.appendChild(downloadLink);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      let data = null;
      reader.onload = () => {
        data = reader.result;
        downloadLink.href = data;
        downloadLink.download = namefile;
        downloadLink.click();
      };
      return { success: true };
    })
    .catch((error) => {
      throw Error(error);
    });
}

async function _processesRequest(request, method, params, optionalsHeaders) {
  console.log(request);
  _getToken();

  const response = fetch(request, {
    method,
    headers: optionalsHeaders ? { ...headers, ...optionalsHeaders } : headers,
    ...(params ? { body: JSON.stringify(params) } : {}),
  });

  const result = await _processResponse(response);
  return result;
}

async function _processesRequestFormData(request, method, params) {
  _getToken();

  const myHeaders = new Headers();

  const user = JSON.parse(sessionStorage.getItem('sesion'));
  const token = user ? user.token : false;
  if (token) myHeaders.append('Authorization', `Bearer ${token}`);

  const response = fetch(request, {
    method,
    headers: myHeaders,
    body: params,
  });

  const result = await _processResponse(response);
  return result;
}

async function _processesRequestDownLoadFile(request, method, params, optionalsHeaders) {
  _getToken();

  const response = fetch(request, {
    method,
    headers: optionalsHeaders ? { ...headers, ...optionalsHeaders } : headers,
    ...(params ? { body: JSON.stringify(params) } : {}),
  });

  const result = await _processCreateLink(response, params.name);
  return result;
}

const api = (urlBase) => ({

  async get(endpoint, params = null, optionalsHeaders = null) {
    console.log(endpoint);
    const result = await _processesRequest(
      `${urlBase}${endpoint}`,
      'GET',
      params,
      optionalsHeaders,
    );
    return result;
  },
  async post(endpoint, params = null, optionalsHeaders = null) {
    const result = await _processesRequest(
      `${urlBase}${endpoint}`,
      'POST',
      params,
      optionalsHeaders,
    );
    return result;
  },
  async put(endpoint, params = null, optionalsHeaders = null) {
    const result = await _processesRequest(
      `${urlBase}${endpoint}`,
      'PUT',
      params,
      optionalsHeaders,
    );
    return result;
  },
  async patch(endpoint, params = null, optionalsHeaders = null) {
    const result = await _processesRequest(
      `${urlBase}${endpoint}`,
      'PATCH',
      params,
      optionalsHeaders,
    );
    return result;
  },
  async delete(endpoint, params = null, optionalsHeaders = null) {
    const result = await _processesRequest(
      `${urlBase}${endpoint}`,
      'DELETE',
      params,
      optionalsHeaders,
    );
    return result;
  },
  async postFormData(endpoint, params = null, optionalsHeaders = null) {
    const result = await _processesRequestFormData(
      `${urlBase}${endpoint}`,
      'POST',
      params,
      optionalsHeaders,
    );
    return result;
  },
  async patchFormData(endpoint, params = null, optionalsHeaders = null) {
    const result = await _processesRequestFormData(
      `${urlBase}${endpoint}`,
      'PATCH',
      params,
      optionalsHeaders,
    );
    return result;
  },
  async postDownLoadFile(endpoint, params = null, optionalsHeaders = null) {
    const result = await _processesRequestDownLoadFile(
      `${urlBase}${endpoint}`,
      'POST',
      params,
      optionalsHeaders,
    );
    return result;
  },
});

export default api;
