import {
  spawn, call, put, takeLatest,
} from 'redux-saga/effects';
import { createAction } from '@reduxjs/toolkit';
import { masterActions } from '../../application/actions/master';
import { applicationActions } from '../../application/actions/application';

import api from '../api/index';

const apiRequest = api(process.env.REACT_APP_API_BAZOOIT);

export function* masterCreate(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(masterActions.postCreateMaster({ connection: true }));
  try {
    const params = e.payload.masterData;
    const url = 'masterData/create';
    const response = yield call(
      apiRequest.post,
      url,
      params,
    );

    // Extraigo todos los datos
    const { success } = response;
    if (success) {
      yield put(masterActions.postCreateMaster({ result: success }));
    } else {
      const { message } = response;
      yield put(masterActions.postCreateMaster({ result: success, message }));
    }
  } catch (err) {
    yield put(masterActions.postCreateMaster({ error: true, result: false }));
    yield put(applicationActions.aplicationErrorSet({ estatus: true, mensaje: err.message }));
  } finally {
    yield put(applicationActions.applicationConnection({ connection: false }));
    yield put(masterActions.postCreateMaster({ connection: false }));
  }
}

export function* masterDisable(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(masterActions.putDisableMaster({ connection: true }));
  try {
    const { masterDataId, kind } = e.payload;
    const url = `/masterData/disable/${masterDataId}/${kind}`;
    const response = yield call(
      apiRequest.put,
      url,
      null,
    );

    // Extraigo todos los datos
    const { success } = response;
    if (success) {
      yield put(masterActions.putDisableMaster({ result: success }));
    } else {
      const { message } = response;
      yield put(masterActions.putDisableMaster({ result: success, message }));
    }
  } catch (err) {
    yield put(masterActions.putDisableMaster({ error: true, result: false }));
    yield put(applicationActions.aplicationErrorSet({ estatus: true, mensaje: err.message }));
  } finally {
    yield put(applicationActions.applicationConnection({ connection: false }));
    yield put(masterActions.putDisableMaster({ connection: false }));
  }
}

export function* masterData() {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(masterActions.getMasterDataMaster({ connection: true }));
  try {
    const url = 'masterData/';
    const response = yield call(
      apiRequest.get,
      url,
      // null,
    );

    // Extraigo todos los datos
    const { success, data } = response;
    if (success) {
      yield put(masterActions.getMasterDataMaster({ result: success, data }));
    } else {
      const { message } = response;
      yield put(masterActions.getMasterDataMaster({ result: success, message }));
    }
  } catch (err) {
    yield put(masterActions.getMasterDataMaster({ error: true, result: false }));
    yield put(applicationActions.aplicationErrorSet({ estatus: true, mensaje: err.message }));
  } finally {
    yield put(applicationActions.applicationConnection({ connection: false }));
    yield put(masterActions.getMasterDataMaster({ connection: false }));
  }
}

export function* masterDataChildren(e) {
  yield put(applicationActions.applicationConnection({ connection: true }));
  yield put(masterActions.getMasterDataChildrenMaster({ connection: true }));
  try {
    const { masterDataId } = e.payload;
    const url = `masterData/children/${masterDataId}`;
    const response = yield call(
      apiRequest.get,
      url,
      null,
    );

    // Extraigo todos los datos
    const { success, data } = response;
    if (success) {
      yield put(masterActions.getMasterDataChildrenMaster({ result: success, data }));
    } else {
      const { message } = response;
      yield put(masterActions.getMasterDataChildrenMaster({ result: success, message }));
    }
  } catch (err) {
    yield put(masterActions.getMasterDataChildrenMaster({ error: true, result: false }));
    yield put(applicationActions.aplicationErrorSet({ estatus: true, mensaje: err.message }));
  } finally {
    yield put(applicationActions.applicationConnection({ connection: false }));
    yield put(masterActions.getMasterDataChildrenMaster({ connection: false }));
  }
}

export function* watchFetchmaster() {
  yield takeLatest(MasterService.activeMasterCreater.type, masterCreate);
  yield takeLatest(MasterService.activeMasterDisable.type, masterDisable);
  yield takeLatest(MasterService.activeMasterData.type, masterData);
  yield takeLatest(MasterService.activeMasterDataChildren.type, masterDataChildren);
}

export default function* rootSaga() {
  yield spawn(watchFetchmaster);
}

const activeMasterCreater = createAction('usuarioSaga/activeMasterCreater');
const activeMasterDisable = createAction('usuarioSaga/activeMasterDisable');
const activeMasterData = createAction('usuarioSaga/activeMasterData');
const activeMasterDataChildren = createAction('usuarioSaga/activeMasterDataChildren');

export const MasterService = {
  activeMasterCreater,
  activeMasterDisable,
  activeMasterData,
  activeMasterDataChildren,
};
