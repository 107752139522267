import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { logger } from 'redux-logger';
// reducers
import reducers from '../../actions';
// sagas
import rootSagas from '../../../infrastructure/services';

// Create the saga middleware.
// This is for the registered saga to listen for actions.
const sagaMiddleware = createSagaMiddleware();

const middleware = [
  ...getDefaultMiddleware({
    immutableStateInvariant: false,
    thunk: false,
  }),
  sagaMiddleware,
];

// Redux logger. Only fro development
if (process.env.NODE_ENV === 'development') {
  /* eslint-disable global-require */
  middleware.push(logger);
}
console.log('App Built at :', process.env.REACT_APP_BUILD_INFO);

const store = configureStore({
  middleware,
  reducer: reducers,
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSagas);

export default store;

/* export const store = configureStore({
  reducer: {
    counter: counterReducer,
  },
});
 */
