const initState = {
  userLocation: '',
  idWorkflowSelected: '',
  idWorkflowGroupSelected: '',
  nameWorkflowSelected: '',
  descriptionWorkflowSelected: '',
  idArtifactSelected: '',
  artifactWorkflowsSelected: '',
};

export default initState;
