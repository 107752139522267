import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

function PrivateRouter({ children, ...rest }) {
  return <Route {...rest} render={({ location }) => children} />;
}

PrivateRouter.propTypes = {
  children: PropTypes.node,
  rest: PropTypes.node,
};

PrivateRouter.defaultProps = {
  children: null,
  rest: null,
};

export default PrivateRouter;
