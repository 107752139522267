import React, { useState } from 'react';

import {
  FormControl,
  Box,
  ButtonGroup,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

function FieldDowload({
  value,
  url,
}) {
  const apiRequest = process.env.REACT_APP_API_BAZOOIT;
  const [dowloadStatus, setDowloadStatus] = useState(false);
  const [errorDow, setErrorDow] = useState(false);

  const dowload = () => {
    setDowloadStatus(true);
    setErrorDow(false);
    const myHeaders = new Headers();
    const user = JSON.parse(sessionStorage.getItem('sesion'));
    const token = user ? user.token : false;
    if (token) myHeaders.append('Authorization', `Bearer ${token}`);
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    fetch(`${apiRequest}${url}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        let downloadLink;
        downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        let data = null;
        reader.onload = () => {
          data = reader.result;
          downloadLink.href = data;
          downloadLink.download = value;
          downloadLink.click();
        };
      })
      .catch(() => {
        setErrorDow(true);
      })
      .finally(() => {
        setDowloadStatus(false);
      });
  };

  return (
    <Box sx={{ mb: 0, mt: 0 }}>
      <FormControl fullWidth>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <ButtonGroup sx={{ pt: 0, zIndex: 5 }} fullWidth variant="contained">
            <LoadingButton
              size="small"
              onClick={() => { dowload(); }}
              endIcon={<CloudDownloadIcon />}
              loading={dowloadStatus}
              loadingPosition="end"
              variant="contained"
              fullWidth
              disabled={!!(value === null || value === '')}
            >
              <span>{value === null || value === '' ? 'No existe archivo cargado' : value}</span>
            </LoadingButton>

          </ButtonGroup>
          {errorDow && <Alert severity="error">Error en la descarga del archivo</Alert>}
        </Stack>
      </FormControl>

    </Box>

  );
}

export default FieldDowload;
