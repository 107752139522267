import React from 'react';
import { Switch, Route } from 'react-router-dom';
// Containers
import PrivateRouter from './privateRouter';
import WorkFlow from '../components/organismos/WorkFlow';
import WorkFlowCreate from '../components/organismos/WorkFlowCreate';
import Templates from '../components/organismos/templates';
import Masterdata from '../components/organismos/Masterdata';

function BazzoitRouter({ handStateMenu }) {
  return (
    <PrivateRouter>
      <Switch>
        <Route exact path="/workFlow/management/*">
          <WorkFlow />
        </Route>
        <Route exact path="/templates/*">
          <Templates />
        </Route>
        <Route exact path="/workflow/create/*">
          <WorkFlowCreate handStateMenu={handStateMenu} />
        </Route>
        <Route exact path="/masterdata/*">
          <Masterdata />
        </Route>
        <Route component={() => <h1>Error 404sdsd</h1>} />
      </Switch>
    </PrivateRouter>
  );
}

export { BazzoitRouter };
