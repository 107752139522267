import { createSlice } from '@reduxjs/toolkit';
import initState from '../../domain/entities/master';

function postCreateMaster(state, action) {
  action.payload.hasOwnProperty('message') && (state.create.data = action.payload.message);
  action.payload.hasOwnProperty('result') && (state.create.result = action.payload.result);
  action.payload.hasOwnProperty('conexion') && (state.create.conexion = action.payload.conexion);
  action.payload.hasOwnProperty('error') && (state.create.error = action.payload.error);
}

function putDisableMaster(state, action) {
  action.payload.hasOwnProperty('message') && (state.disable.data = action.payload.message);
  action.payload.hasOwnProperty('result') && (state.disable.result = action.payload.result);
  action.payload.hasOwnProperty('conexion') && (state.disable.conexion = action.payload.conexion);
  action.payload.hasOwnProperty('error') && (state.disable.error = action.payload.error);
}

function getMasterDataMaster(state, action) {
  action.payload.hasOwnProperty('data') && (state.masterData.data = action.payload.data);
  action.payload.hasOwnProperty('message') && (state.masterData.data = action.payload.message);
  action.payload.hasOwnProperty('result') && (state.masterData.result = action.payload.result);
  action.payload.hasOwnProperty('conexion') && (state.masterData.conexion = action.payload.conexion);
  action.payload.hasOwnProperty('error') && (state.masterData.error = action.payload.error);
}

function getMasterDataChildrenMaster(state, action) {
  action.payload.hasOwnProperty('data') && (state.masterDataChildren.data = action.payload.data);
  action.payload.hasOwnProperty('message') && (state.masterDataChildren.data = action.payload.message);
  action.payload.hasOwnProperty('result') && (state.masterDataChildren.result = action.payload.result);
  action.payload.hasOwnProperty('conexion') && (state.masterDataChildren.conexion = action.payload.conexion);
  action.payload.hasOwnProperty('error') && (state.masterDataChildren.error = action.payload.error);
}

/**
 * Conexión
 */
const masterReducer = createSlice({
  name: 'master',
  initialState: initState,
  reducers: {
    postCreateMaster,
    putDisableMaster,
    getMasterDataMaster,
    getMasterDataChildrenMaster,
  },
});

export const masterActions = {
  ...masterReducer.actions,
};

export default masterReducer.reducer;
