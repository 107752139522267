import { createSlice } from '@reduxjs/toolkit';
import initState from '../../domain/entities/session';

// LOGIN

function createSession(state, action) {
  state.token = action.payload.token;
  state.email = action.payload.email;
  state.id = action.payload.id;
  state.firstName = action.payload.firstName;
  state.lastName = action.payload.lastName;
  state.connection = false;
  state.error = false;
}

function connectionSession(state, action) {
  state.connection = action.payload.connection;
}

function errorSession(state, action) {
  state.error = action.payload.error;
  state.message = action.payload.message;
}

// PERFIL
function createProfile(state, action) {
  state.perfiles = action.payload;
  state.connection = false;
  state.error = false;
}

function ConnectionProfile(state, action) {
  state.connection = action.payload.connection;
}

function errorProfile(state, action) {
  state.error = action.payload.error;
}

// RECOVERY SESION
function recoverPassword(state, action) {
  action.payload.hasOwnProperty('message') && (state.recoverPassword.message = action.payload.message);
  action.payload.hasOwnProperty('result') && (state.recoverPassword.result = action.payload.result);
  action.payload.hasOwnProperty('connection') && (state.recoverPassword.connection = action.payload.connection);
  action.payload.hasOwnProperty('error') && (state.recoverPassword.error = action.payload.error);
}

// CHANGE PASSWORD
function changePassword(state, action) {
  action.payload.hasOwnProperty('message') && (state.changePassword.message = action.payload.message);
  action.payload.hasOwnProperty('result') && (state.changePassword.result = action.payload.result);
  action.payload.hasOwnProperty('connection') && (state.changePassword.connection = action.payload.connection);
  action.payload.hasOwnProperty('error') && (state.changePassword.error = action.payload.error);
}

/**
 * Conexión
 */
const sessionReducer = createSlice({
  name: 'session',
  initialState: initState,
  reducers: {
    createSession,
    connectionSession,
    errorSession,
    createProfile,
    ConnectionProfile,
    errorProfile,
    recoverPassword,
    changePassword,
  },
});

export const sesionActions = {
  ...sessionReducer.actions,
};

export default sessionReducer.reducer;
