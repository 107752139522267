import { createSlice } from '@reduxjs/toolkit';
import initState from '../../domain/entities/workFlow';

function createFlow(state, action) {
  state.workflows = action.payload.data.rows;
  state.message = action.payload.message;
  state.connection = false;
  state.error = false;
}

function connectionFlow(state, action) {
  state.connection = action.payload.connection;
}

function errorFlow(state, action) {
  state.workflows = [];
  state.message = action.payload.message;
  state.connection = false;
  state.error = true;
}

function createTypesFlow(state, action) {
  state.workflowTypes = action.payload.data;
  state.connection = false;
  state.error = false;
}

// create GRUPOS FLUJO
function createGroupsFlows(state, action) {
  state.createWorkflowsGroup.create = action.payload.create;
}

function createGroupsFlowsConnection(state, action) {
  state.createWorkflowsGroup.connection = action.payload.connection;
}

function createGroupsFlowsError(state, action) {
  state.createWorkflowsGroup.message = action.payload.message;
  state.createWorkflowsGroup.error = action.payload.error;
}

function createGroupsFlowsReset(state, action) {
  state.createWorkflowsGroup.create = false;
  state.createWorkflowsGroup.message = '';
  state.createWorkflowsGroup.connection = false;
  state.createWorkflowsGroup.error = false;
}

// create FLUJO
function createFlows(state, action) {
  state.createWorkflow.create = action.payload.create;
}

function createFlowsConnection(state, action) {
  state.createWorkflow.connection = action.payload.connection;
}

function createFlowsError(state, action) {
  state.createWorkflow.message = action.payload.message;
  state.createWorkflow.error = action.payload.error;
}

function createFlowsReset(state, action) {
  state.createWorkflow.modificado = false;
  state.createWorkflow.message = '';
  state.createWorkflow.connection = false;
  state.createWorkflow.error = false;
}

// MODIFICAR workflows
function modificarFlujo(state, action) {
  state.modifyWorkflows.modificado = action.payload.modificado;
}

function modificarFlujoConnection(state, action) {
  state.modifyWorkflows.connection = action.payload.connection;
}

function modificarFlujoError(state, action) {
  state.modifyWorkflows.message = action.payload.message;
  state.modifyWorkflows.error = action.payload.error;
}

function modificarFlujoReset(state, action) {
  state.modifyWorkflows.modificado = false;
  state.modifyWorkflows.message = '';
  state.modifyWorkflows.connection = false;
  state.modifyWorkflows.error = false;
}

const workflowReducer = createSlice({
  name: 'workflow',
  initialState: initState,
  reducers: {
    createFlow,
    connectionFlow,
    errorFlow,
    createTypesFlow,

    createGroupsFlows,
    createGroupsFlowsConnection,
    createGroupsFlowsError,
    createGroupsFlowsReset,

    createFlows,
    createFlowsConnection,
    createFlowsError,
    createFlowsReset,

    modificarFlujo,
    modificarFlujoConnection,
    modificarFlujoError,
    modificarFlujoReset,

  },
});

export const flujoActions = {
  ...workflowReducer.actions,
};

export default workflowReducer.reducer;
