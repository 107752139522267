const initState = {

  create: {
    result: false,
    message: '',
    conexion: false,
    error: false,
  },
  disable: {
    result: false,
    message: '',
    conexion: false,
    error: false,
  },
  masterData: {
    data: [],
    result: false,
    message: '',
    conexion: false,
    error: false,
  },
  masterDataChildren: {
    data: [],
    result: false,
    message: '',
    conexion: false,
    error: false,
  },

};

export default initState;
