import { createSlice } from '@reduxjs/toolkit';
import initState from '../../domain/entities/company';

// LOGIN

function iniciarcompany(state, action) {
  state.id = action.payload.id ? action.payload.id : null;
  state.rut = action.payload.rut ? action.payload.rut : null;
  state.company = action.payload.company ? action.payload.company : null;
  state.abog_patroc = action.payload.abog_patroc ? action.payload.abog_patroc : null;
  state.rut_abog_patroc = action.payload.rut_abog_patroc ? action.payload.rut_abog_patroc : null;
  state.abog_deman = action.payload.abog_deman ? action.payload.abog_deman : null;
  state.rut_abog_deman = action.payload.rut_abog_deman ? action.payload.rut_abog_deman : null;
  state.connection = false;
  state.error = false;
}

function companySetId(state, action) {
  state.id = action.payload.id ? action.payload.id : null;
  state.connection = false;
}

function connectioncompany(state, action) {
  state.connection = action.payload.connection;
}

function errorcompany(state, action) {
  state.error = action.payload.error;
  state.message = action.payload.message;
}

/**
 * Conexión
 */
const companyReducer = createSlice({
  name: 'company',
  initialState: initState,
  reducers: {
    iniciarcompany,
    companySetId,
    connectioncompany,
    errorcompany,
  },
});

export const companyActions = {
  ...companyReducer.actions,
};

export default companyReducer.reducer;
