import { fork, all } from 'redux-saga/effects';
import sesionSaga from './session';
import companySaga from './company';
import workFlowSaga from './workFlow';
import cardsSaga from './cards';
import templatesSaga from './template';
import masterSaga from './maestros';
import comentsService from './coments';

export default function* rootSaga() {
  yield all([
    fork(sesionSaga),
    fork(companySaga),
    fork(workFlowSaga),
    fork(cardsSaga),
    fork(templatesSaga),
    fork(masterSaga),
    fork(comentsService),
  ]);
}
