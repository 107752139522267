import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl,
  Button,
  Divider,
  Box,
  ButtonGroup,
  CardContent,
  Grid,
  Paper,
} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import moment from 'moment';
import Chip from '@mui/material/Chip';
import Crop75Icon from '@mui/icons-material/Crop75';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TodayIcon from '@mui/icons-material/Today';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

// import { artifactActionsSaga } from '../../../../infrastructure/services/artifact';

function ArtefactoTimeLive({
  artefactoMostar,
  card,
  handleCloseMostrar,
}) {
  const dispatch = useDispatch();

  const { connection, error, timeLive } = useSelector((store) => store.bazooit.cards.cardTimeLive);
  let flujoMarca = '';

  const capitalizarPrimeraLetra = (str) => {
    if (str === null || str === '') {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    // dispatch(artifactActionsSaga.activesearchTimeLineArtifact({ idArtefacto: card.id }));

  }, []);

  const obtenerDetalleArtefacto = (detalleIn) => {
    const detalle = detalleIn.map((formulario, punteroFormulario) => (
      <>
        {formulario.campos.map((campos, punteroCamos) => (

          campos.display && punteroFormulario === 0 && punteroCamos >= 1 && (
          <Chip size="small" sx={{ mr: 1 }} label={`${capitalizarPrimeraLetra(campos.nombreCampo)} : ${campos.valor === null ? '' : campos.valor}`} />

          )
                     || campos.display && punteroFormulario >= 1 && (
                     <Chip size="small" sx={{ mr: 1 }} label={`${capitalizarPrimeraLetra(campos.nombreCampo)} : ${campos.valor === null ? '' : campos.valor}`} />

                     )
                     || campos.display && punteroFormulario >= 1 && (
                     <Chip size="small" sx={{ mr: 1 }} label={`${capitalizarPrimeraLetra(campos.nombreCampo)} : ${campos.valor === null ? '' : campos.valor}`} />

                     )

        ))}
      </>
    ));
    return detalle;
  };

  const obtenerFlujo = (flujIn, index) => {
    if (index === 0) {
      flujoMarca = flujIn;
      return <Divider><Chip label={`Flujo: ${capitalizarPrimeraLetra(flujIn)}`} /></Divider>;
    }

    if (flujoMarca === flujIn) {
      return '';
    }
    flujoMarca = flujIn;
    return <Divider><Chip label={`Flujo: ${capitalizarPrimeraLetra(flujIn)}`} /></Divider>;
  };

  /*  */

  return (
    <Dialog
      disableEscapeKeyDown
      open={artefactoMostar}
      onClose={handleCloseMostrar}
      fullWidth="sm"
      maxWidth="sm"
    >
      <DialogTitle sx={{ lineHeight: 1.2 }}>
        <Box>
          <Typography sx={{ pb: 0, mb: 0 }} variant="h5" component="p">{card.visibleFieldsValues[0]}</Typography>
        </Box>
        {/* obtenerDetalleArtefacto(card.datos.datos) */}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ pl: 0, pr: 0 }}>
        <Box sx={{ pl: 3, pr: 3 }}>
          <Typography>
            <Box>

              <CardContent sx={{ m: 0, p: 0, mt: 1 }}>
                <Typography>
                  {/* JSON.stringify(timeLive) */}

                  <Timeline position="right">

                    {/*
                                                "tipo": "etapas",
                                                "glosaTipo": "B",
                                                "": "Nelson  Hernandez ",
                                                "": "",
                                                "": "2023-07-20 11:07:03",
                                                "tiempoTranscurrido": "2023-07-20 11:07:03"
                                                */}

                    { }
                    {timeLive.map((itemGrupo, indexItemGrupo) => (

                      <>
                        {itemGrupo.tipo === 'etapas' && itemGrupo.flujo && itemGrupo.flujo !== '' && (
                        <>
                          {obtenerFlujo(itemGrupo.flujo, indexItemGrupo)}

                        </>
                        )}

                        <TimelineItem>

                          <TimelineOppositeContent
                            sx={{ m: 'auto 0' }}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                          >
                            {/* {itemGrupo.fechaEjecucion} */}
                            <Grid container direction="row" alignItems="center" flexDirection="row-reverse">
                              <TodayIcon sx={{ ml: 1 }} fontSize="small" />
                              {moment(itemGrupo.fechaEjecucion).format('YYYY-MM-DD')}
                            </Grid>
                            <Grid container direction="row" alignItems="center" flexDirection="row-reverse">
                              <AccessTimeIcon sx={{ ml: 1 }} fontSize="small" />
                              {moment(itemGrupo.fechaEjecucion).format('HH:MM')}
                            </Grid>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot size="small" color="primary" variant={itemGrupo.tipo !== 'etapas' ? 'outlined' : 'filled'}>
                              {itemGrupo.tipo === 'etapas' ? <ArrowForwardIcon sx={{ m: 1 }} /> : <Crop75Icon />}
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: itemGrupo.tipo === 'etapas' ? '50px' : '10px', px: 2 }}>
                            <Typography variant="h6" component="span">
                              {itemGrupo.glosaTipo}
                            </Typography>
                            {itemGrupo.tipo === 'etapas' && itemGrupo.flujo && itemGrupo.flujo !== '' && (
                            <Typography fontSize="small">{itemGrupo.flujo}</Typography>

                            )}
                            <Typography>{itemGrupo.glosaUsuario}</Typography>
                            {itemGrupo.observaciones && itemGrupo.observaciones !== '' && (
                            <Typography fontSize="small">{itemGrupo.observaciones}</Typography>
                            )}
                          </TimelineContent>

                        </TimelineItem>
                      </>

                    ))}

                    {/* <TimelineItem>
                                                    <TimelineOppositeContent
                                                        sx={{ m: 'auto 0' }}
                                                        variant="body2"
                                                        color="text.secondary"
                                                    >
                                                        10:00 am
                                                    </TimelineOppositeContent>
                                                    <TimelineSeparator>
                                                        <TimelineConnector />
                                                        <TimelineDot color="primary">
                                                            <LaptopMacIcon />
                                                        </TimelineDot>
                                                        <TimelineConnector />
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                                                        <Typography variant="h6" component="span">
                                                            Code
                                                        </Typography>
                                                        <Typography>Because it&apos;s awesome!</Typography>
                                                    </TimelineContent>
                                                </TimelineItem>
                                                <TimelineItem>
                                                    <TimelineSeparator>
                                                        <TimelineConnector />
                                                        <TimelineDot color="primary" variant="outlined">
                                                            <HotelIcon />
                                                        </TimelineDot>
                                                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{ py: '102px', px: 2 }}>
                                                        <Typography variant="h6" component="span">
                                                            Sleep
                                                        </Typography>
                                                        <Typography>Because you need rest</Typography>
                                                    </TimelineContent>
                                                </TimelineItem>
                                                <TimelineItem>
                                                    <TimelineSeparator>
                                                        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                                                        <TimelineDot color="secondary">
                                                            <RepeatIcon />
                                                        </TimelineDot>
                                                        <TimelineConnector />
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                                                        <Typography variant="h6" component="span">
                                                            Repeat
                                                        </Typography>
                                                        <Typography>Because this is the life you love!</Typography>
                                                    </TimelineContent>
                                                </TimelineItem> */}
                  </Timeline>

                  {/* {card.datos.datos.map((itemGrupo, indexItemGrupo)=>(
                                                <Box sx={{pb:2}}>
                                                    <Typography variant="h6" color="text.promary" >{capitalizarPrimeraLetra(itemGrupo.nombreGrupo)}</Typography>
                                                    {itemGrupo.campos.map((itemCampo, indexItemCampo)=>(
                                                        <Typography sx={{pl:1}}variant="body2" color="text.secondary">
                                                            {capitalizarPrimeraLetra(itemCampo.nombreCampo)}: {capitalizarPrimeraLetra(itemCampo.valor)}
                                                        </Typography>
                                                    ))}

                                                </Box>
                                            ))} */}
                </Typography>
              </CardContent>

            </Box>
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ pl: 3, pr: 3, pt: 2 }}>
          <Typography>
            <Box sx={{ mb: 0, mt: 0 }}>
              <FormControl fullWidth>
                <ButtonGroup sx={{ pt: 0, zIndex: 5 }} fullWidth variant="contained">
                  <Button onClick={(e) => { handleCloseMostrar(e); }}>
                    Salir
                  </Button>
                </ButtonGroup>
              </FormControl>
            </Box>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>

  );
}

export default ArtefactoTimeLive;
