import {
  spawn, call, put, takeLatest,
} from 'redux-saga/effects';
import { createAction } from '@reduxjs/toolkit';
import { companyActions } from '../../application/actions/company';
import { sesionActions } from '../../application/actions/session';

import api from '../api/index';

const apiRequest = api(process.env.API_BAZOOIT);

// Razon Social
export function* company(e) {
  yield put(companyActions.connectioncompanycompany({ connection: true }));
  yield put(sesionActions.connectionSession({ connection: true }));
  try {
    const params = e.payload;
    const url = 'login/company';
    const response = yield call(
      apiRequest.post,
      url,
      params,
    );

    const { data, result, message } = response;
    if (result) {
      // company
      yield put(companyActions.iniciarcompany(e.payload.company));
      yield put(companyActions.companySetId({ id: data.idcompany }));

      // Sesion
      // const {token } = data;
      sessionStorage.setItem('sesion', JSON.stringify(data));
      yield put(sesionActions.createSession(data));
    } else {
      yield put(companyActions.errorcompany({ error: true, message }));
      yield put(companyActions.connectioncompany({ connection: false }));
      yield put(sesionActions.connectionSession({ connection: false }));
    }
  } catch (err) {
    yield put(companyActions.connectioncompany({ connection: false }));
    yield put(companyActions.errorcompany({ error: true, message: err.message }));

    yield put(sesionActions.connectionSession({ connection: false }));
    yield put(sesionActions.errorSession({ error: err.message }));
  } finally {
  }
}

export function* watchFetchcompany() {
  yield takeLatest(companyActionsSaga.activecompany.type, company);
}

export default function* rootSaga() {
  yield spawn(watchFetchcompany);
}

const activecompany = createAction('reazonSocialSaga/activecompany');

export const companyActionsSaga = {
  activecompany,
};
