import { createSlice } from '@reduxjs/toolkit';
import initState from '../../domain/entities/template';

function getTemplate(state, action) {
  action.payload.hasOwnProperty('data') && (state.get.data = action.payload.data);
  action.payload.hasOwnProperty('result') && (state.get.result = action.payload.result);
  action.payload.hasOwnProperty('conexion') && (state.get.conexion = action.payload.conexion);
  action.payload.hasOwnProperty('error') && (state.get.error = action.payload.error);
}

function verifyTemplate(state, action) {
  action.payload.hasOwnProperty('fields') && (state.verify.fields = action.payload.fields);
  action.payload.hasOwnProperty('result') && (state.verify.result = action.payload.result);
  action.payload.hasOwnProperty('conexion') && (state.verify.conexion = action.payload.conexion);
  action.payload.hasOwnProperty('error') && (state.verify.error = action.payload.error);
  action.payload.hasOwnProperty('message') && (state.verify.message = action.payload.message);
}

// OBTENER
function createTemplate(state, action) {
  action.payload.hasOwnProperty('data') && (state.create.data = action.payload.data);
  action.payload.hasOwnProperty('result') && (state.create.result = action.payload.result);
  action.payload.hasOwnProperty('conexion') && (state.create.conexion = action.payload.conexion);
  action.payload.hasOwnProperty('error') && (state.create.error = action.payload.error);
  action.payload.hasOwnProperty('message') && (state.create.message = action.payload.message);
}
// CREAR
function updateTemplate(state, action) {
  action.payload.hasOwnProperty('data') && (state.update.data = action.payload.data);
  action.payload.hasOwnProperty('result') && (state.update.result = action.payload.result);
  action.payload.hasOwnProperty('conexion') && (state.update.conexion = action.payload.conexion);
  action.payload.hasOwnProperty('error') && (state.update.error = action.payload.error);
}

function deleteTemplate(state, action) {
  action.payload.hasOwnProperty('data') && (state.delete.data = action.payload.data);
  action.payload.hasOwnProperty('result') && (state.delete.result = action.payload.result);
  action.payload.hasOwnProperty('conexion') && (state.delete.conexion = action.payload.conexion);
  action.payload.hasOwnProperty('error') && (state.delete.error = action.payload.error);
}

/**
 * Conexión
 */
const templatesReducer = createSlice({
  name: 'template',
  initialState: initState,
  reducers: {
    getTemplate,
    verifyTemplate,
    createTemplate,
    updateTemplate,
    deleteTemplate,
  },
});

export const templatesActions = {
  ...templatesReducer.actions,
};

export default templatesReducer.reducer;
