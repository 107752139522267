import { createSlice } from '@reduxjs/toolkit';
import initState from '../../domain/entities/user';

// LOGIN

function createUser(state, action) {
  state.dataUsuarioParaInmueble = action.payload.usuario;
}

function connectionUser(state, action) {
  state.connection = action.payload.connection;
}

function errorUser(state, action) {
  state.error = action.payload.error;
}

function registerUser(state, action) {
  state.dataUsuarioRegistrado = action.payload.dataUsuarioRegistrado;
  state.dataUsuarioRegistradomensaje = action.payload.dataUsuarioRegistradomensaje;
}

/**
 * Conexión
 */
const usuarioReducer = createSlice({
  name: 'usuario',
  initialState: initState,
  reducers: {
    createUser,
    connectionUser,
    errorUser,
    registerUser,

  },
});

export const usuarioActions = {
  ...usuarioReducer.actions,
};

export default usuarioReducer.reducer;
