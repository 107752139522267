import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Login from '../../organismos/login';
import { SessionActionsSaga } from '../../../../infrastructure/services/session';

function InitSession({ recovery }) {
  const dispatch = useDispatch();
  const connectionSesion = useSelector((store) => store.bazooit.session.connection);
  const handleLogin = (email, password) => {
    dispatch(SessionActionsSaga.activeSession({ email, password }));
  };

  const heandleRecoverPaswoard = (email) => {
    dispatch(SessionActionsSaga.activeRecoverPassword(email));
  };

  const handlerChangePassword = (token, password) => {
    dispatch(SessionActionsSaga.activeChangePasswordd({ token, password }));
  };

  const token = useSelector((store) => store.bazooit.session.token);

  return (
    <Container fixed>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Login
          handleLogin={handleLogin}
          statusConnection={connectionSesion}
          heandleRecoverPaswoard={heandleRecoverPaswoard}
          handlerChangePassword={handlerChangePassword}
          recovery={recovery}
        />
      </Grid>
    </Container>
  );
}

export default InitSession;
