import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  BrowserRouter, Switch, Route, useHistory, withRouter,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import HomeIcon from '@mui/icons-material/Home';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import Badge from '@mui/material/Badge';
import {
  red, blue, lightGreen, blueGrey,
} from '@mui/material/colors';
import { storageBrowserActions } from '../../../../application/actions/storageBrowser';
import './index.css';

function MenuBazooit({ stateMenu, handStateMenu, controlInterfaz }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { workflows, connection, message } = useSelector((store) => store.bazooit.workflowStorage);
  const { privilegios } = useSelector((store) => store.bazooit.session);
  const grey = '#47648f';
  let tituloListaPrivilegios = '';
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    // CREO GRUPOS
    const newWmenu = [];
    for (let i = 0; privilegios.length > i; ++i) {
      if (retornarTituloPrivilegiosMenu(privilegios[i].grupo) != '') {
        newWmenu.push({ grupo: privilegios[i].grupo, opciones: [] });
      }
    }
    // LENO GRUPOS
    for (let A = 0; newWmenu.length > A; ++A) {
      for (let B = 0; privilegios.length > B; ++B) {
        if (newWmenu[A].grupo === privilegios[B].grupo) {
          newWmenu[A].opciones.push({ opcion: privilegios[B].opcion, url: privilegios[B].accion });
        }
      }
    }
    setMenu(newWmenu);
  }, [privilegios]);

  const retornarTituloPrivilegiosMenu = (item) => {
    if (tituloListaPrivilegios != item) {
      tituloListaPrivilegios = item;
      return item;
    }
    return '';
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    handStateMenu(open);
  };

  const handleClick = (evento, idFlujo, titulo, grupo, idGrupo, artefacto, description) => {
    // handStateMenu(false);
    // controlInterfaz(evento, idFlujo, titulo);
    dispatch(storageBrowserActions.update({
      idWorkflowSelected: idFlujo,
      nameWorkflowSelected: titulo,
      idArtifactSelected: '',
      userLocation: evento,
      nombreGrupoworkflowselecionado: grupo,
      idWorkflowGroupSelected: idGrupo,
      artifactWorkflowsSelected: artefacto,
      descriptionWorkflowSelected: description,
    }));
    history.push(evento);
  };

  const capitalizarPrimeraLetra = (str) => {
    if (str === null || str === '') {
      return '';
    }
    return str;
    // return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
  };

  const retornarTituloPrivilegios = (item) => {
    if (tituloListaPrivilegios != item) {
      tituloListaPrivilegios = item;
    } else {
      return '';
    }
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
            // onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >

      <Divider />

      <List sx={{ p: 0 }}>

        {menu.filter((x) => x.grupo !== 'workflows').length > 0 && (
        <Box spacing={4} padding={1} sx={{ pl: 0, pt: 3 }} textAlign="left">
          <Typography sx={{ fontWeight: 100 }} variant="h6" className="tituloRows">Gestión de App</Typography>
        </Box>
        )}

        {menu.map((item, puntero) => (
          <>
            {item.grupo !== 'workflows' && (
            <Accordion key={puntero} disableGutters sx={{ background: 'none', color: '#FFF', boxShadow: 'none' }} square>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{ fontWeight: 100, pl: 1 }}>{capitalizarPrimeraLetra(item.grupo)}</Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ p: 0, background: grey }}>

                {item.opciones.map((item, puntero) => (
                  <>
                    {item.opcion != 'Ver Flujo' && (
                    <ListItem key={puntero} sx={{ p: 0, m: 0 }}>
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText sx={{ fontWeight: 100 }} onClick={() => handleClick(item.url, 0, item.opcion, '', '')} primary={<Typography sx={{ fontWeight: 100 }}>{item.opcion}</Typography>} />
                      </ListItemButton>
                    </ListItem>
                    )}
                  </>
                ))}

              </AccordionDetails>
            </Accordion>
            )}

            {item.grupo === 'workflows' && (
            <>
              <Box key={puntero} spacing={4} padding={1} sx={{ pl: 0, pt: 3 }} textAlign="left">
                <Typography sx={{ fontWeight: 100 }} variant="h6" className="tituloRows">{item.grupo}</Typography>
              </Box>

              {workflows.map((workflowStorage, index) => (
                <>

                  <ListItem key={index} sx={{ p: 0, m: 0 }}>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemText onClick={() => handleClick(`${item.opciones[0].url}${workflowStorage.name}`, workflowStorage.id, workflowStorage.name, workflowStorage.name, workflowStorage.id, null, workflowStorage.description)} primary={<Typography sx={{ fontWeight: 100 }}>{capitalizarPrimeraLetra(workflowStorage.name)}</Typography>} />
                    </ListItemButton>
                  </ListItem>

                  {/* <Accordion disableGutters sx={{ background: 'none', color: '#FFF', boxShadow: 'none' }} square={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography sx={{ fontWeight: 100, pl: 1 }} >{capitalizarPrimeraLetra(workflowStorage.name)}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails sx={{ p: 0, background: grey }}>
                                                {workflowStorage.workflows.map((flujo, index) => (
                                                    <>  <Divider />
                                                        <ListItem key={index} sx={{ p: 0, m: 0 }}>
                                                            <ListItemButton sx={{ pl: 4 }}>
                                                                <ListItemText onClick={() => handleClick(`${item.opciones[0].url}${flujo.nombrFlujo}`, flujo.id, flujo.nombrFlujo, workflowStorage.nombre, workflowStorage.id, workflowStorage.artefactos[0])} primary={<Typography sx={{ fontWeight: 100 }} >{capitalizarPrimeraLetra(flujo.nombrFlujo)}</Typography>} />
                                                            </ListItemButton>
                                                        </ListItem>

                                                    </>

                                                ))}
                                            </AccordionDetails>
                                        </Accordion> */}
                </>
              ))}

            </>
            )}

          </>
        ))}
      </List>

    </Box>
  );

  return (
    <div>
      <Drawer
        anchor="left"
        open={stateMenu}
        onClose={toggleDrawer(false)}
        className="appBar"
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default MenuBazooit;
