const initState = {
  dataUsuarioParaInmueble: null,
  dataUsuarioRegistrado: false,
  dataUsuarioRegistradomensaje: '',
  connection: false,
  error: false,
  envioAgregar: false,
};

export default initState;
