import { createSlice } from '@reduxjs/toolkit';
import initState from '../../domain/entities/coments';

function createComentsActions(state, action) {
  console.log(action.payload);
  action.payload.hasOwnProperty('result') && (state.createComents.result = action.payload.result);
  action.payload.hasOwnProperty('connection') && (state.createComents.connection = action.payload.connection);
  action.payload.hasOwnProperty('error') && (state.createComents.error = action.payload.error);
}
function getComentsActions(state, action) {
  action.payload.hasOwnProperty('coments') && (state.getComents.coments = action.payload.coments);
  action.payload.hasOwnProperty('result') && (state.getComents.result = action.payload.result);
  action.payload.hasOwnProperty('connection') && (state.getComents.connection = action.payload.connection);
  action.payload.hasOwnProperty('error') && (state.getComents.error = action.payload.error);
}

function updateComentsActions(state, action) {
  action.payload.hasOwnProperty('result') && (state.updateComents.result = action.payload.result);
  action.payload.hasOwnProperty('connection') && (state.updateComents.connection = action.payload.connection);
  action.payload.hasOwnProperty('error') && (state.updateComents.error = action.payload.error);
}

function deleteComentsActions(state, action) {
  action.payload.hasOwnProperty('result') && (state.deleteComents.result = action.payload.result);
  action.payload.hasOwnProperty('connection') && (state.deleteComents.connection = action.payload.connection);
  action.payload.hasOwnProperty('error') && (state.deleteComents.error = action.payload.error);
}

const comentsReducer = createSlice({
  name: 'coments',
  initialState: initState,
  reducers: {
    createComentsActions,
    getComentsActions,
    updateComentsActions,
    deleteComentsActions,
  },
});

export const comentsActions = {
  ...comentsReducer.actions,
};

export default comentsReducer.reducer;
