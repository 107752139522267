import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider, ReactReduxContext } from 'react-redux';
import store from './application/config/store';
import App from './ui/pages';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(

  <Provider store={store} context={ReactReduxContext}>
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode>  */}
  </Provider>,

);
