const initState = {
  token: null,
  email: null,
  id: null,
  firstName: null,
  lastName: null,
  connection: false,
  error: false,
  privilegios: [
    {
      id: 2,
      grupo: 'Gestión de workflows',
      opcion: 'crear un nuevo flujo',
      accion: '/workflow/create/',
      estatus: true,
    },
    {
      id: 3,
      grupo: 'Gestión de Documentos',
      opcion: 'Ver documentos',
      accion: '/templates/',
      estatus: true,
    },
    {
      id: 4,
      grupo: 'Maestros',
      opcion: 'Ver Maestros',
      accion: '/masterdata/',
      estatus: true,
    },
    {
      id: 5,
      grupo: 'workflows',
      opcion: 'Ver Flujo',
      accion: '/workFlow/management/',
      estatus: true,
    },
  ],
  message: '',
  recoverPassword: {
    result: false,
    connection: false,
    error: false,
    message: '',
  },
  changePassword: {
    result: false,
    connection: false,
    error: false,
    message: '',
  },
};

export default initState;
