import { createSlice } from '@reduxjs/toolkit';
import initState from '../../domain/entities/application';

function applicationConnection(state, action) {
  state.connection = action.payload.connection;
}

// Just Errors
function aplicationErrorSet(state, action) {
  state.notification.status = action.payload.status;
  state.notification.tipo = 'error';
  state.notification.mensaje = action.payload.mensaje;
}

function aplicationErrorReSet(state, action) {
  state.notification.status = false;
  state.notification.tipo = 'info';
  state.notification.mensaje = '';
}

const applicationReducer = createSlice({
  name: 'application',
  initialState: initState,
  reducers: {
    applicationConnection,
    aplicationErrorSet,
    aplicationErrorReSet,

  },
});

export const applicationActions = {
  ...applicationReducer.actions,
};

export default applicationReducer.reducer;
