import {
  spawn, call, put, takeLatest,
} from 'redux-saga/effects';
import { createAction } from '@reduxjs/toolkit';
import { sesionActions } from '../../application/actions/session';

import api from '../api/index';

const apiRequest = api(process.env.REACT_APP_API_BAZOOIT);

// SESION
export function* validateSession(e) {
  yield put(sesionActions.connectionSession({ connection: true }));
  try {
    const params = e.payload;
    const url = 'auth/login';
    const response = yield call(
      apiRequest.post,
      url,
      params,
    );

    // Extraigo todos los datos
    const { success, message, data } = response;

    if (success && data) {
      sessionStorage.setItem('sesion', JSON.stringify(data));
      yield put(sesionActions.createSession(data));
    } else {
      yield put(sesionActions.errorSession({ error: true, message }));
    }
  } catch (err) {
    yield put(sesionActions.errorSession({ error: true, message: err.message }));
  } finally {
    yield put(sesionActions.connectionSession({ connection: false }));
  }
}

// PERFIL

export function* validateProfile(e) {
  yield put(sesionActions.ConnectionProfile({ Perfiles: true }));
  try {
    const url = 'perfil';
    const response = yield call(
      apiRequest.get,
      url,
    );

    // Extraigo todos los datos
    const { data } = response;
    yield put(sesionActions.createProfile(data));
  } catch (err) {
    yield put(sesionActions.errorProfile({ error: err.message }));
  } finally {
    yield put(sesionActions.ConnectionProfile({ Perfiles: false }));
  }
}

export function* recoverPassword(e) {
  yield put(sesionActions.recoverPassword({ connection: true }));
  yield put(sesionActions.connectionSession({ connection: true }));
  try {
    const params = { email: e.payload };
    const url = 'auth/recover-password';
    const response = yield call(
      apiRequest.post,
      url,
      params,
    );

    // Extraigo todos los datos
    const { success, message, data } = response;

    if (success) {
      yield put(sesionActions.recoverPassword({ result: true, message, connection: false }));
    } else {
      yield put(sesionActions.recoverPassword({ error: true, message, connection: false }));
    }
  } catch (err) {
    yield put(sesionActions.recoverPassword({
      error: true,
      message: err.message,
      connection: false,
    }));
  } finally {
    yield put(sesionActions.connectionSession({ connection: false }));
  }
}

export function* changePassword(e) {
  yield put(sesionActions.changePassword({
    connection: true, result: false, error: false, message: '',
  }));
  yield put(sesionActions.connectionSession({ connection: true }));
  try {
    const params = e.payload;
    const url = 'auth/change-password';
    const response = yield call(
      apiRequest.post,
      url,
      params,
    );

    // Extraigo todos los datos
    const { success, message } = response;

    if (success) {
      yield put(sesionActions.changePassword({ result: true, message, connection: false }));
    } else {
      yield put(sesionActions.changePassword({ error: true, message, connection: false }));
    }
  } catch (err) {
    yield put(sesionActions.changePassword({
      error: true,
      message: err.message,
      connection: false,
    }));
  } finally {
    yield put(sesionActions.connectionSession({ connection: false }));
  }
}

export function* watchFetchSession() {
  yield takeLatest(SessionActionsSaga.activeSession.type, validateSession);
  yield takeLatest(SessionActionsSaga.activeProfile.type, validateProfile);
  yield takeLatest(SessionActionsSaga.activeRecoverPassword.type, recoverPassword);
  yield takeLatest(SessionActionsSaga.activeChangePasswordd.type, changePassword);
}

export default function* rootSaga() {
  yield spawn(watchFetchSession);
}

const activeSession = createAction('sessionSaga/activeSesion');
const activeProfile = createAction('sessionSaga/activePerfil');
const activeRecoverPassword = createAction('sessionSaga/activeRecoverPassword');
const activeChangePasswordd = createAction('sessionSaga/activeChangePasswordd');

export const SessionActionsSaga = {
  activeSession,
  activeProfile,
  activeRecoverPassword,
  activeChangePasswordd,
};
