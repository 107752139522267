const initState = {
  get: {
    data: [],
    result: false,
    conexion: false,
    error: false,
  },
  verify: {
    result: false,
    message: '',
    conexion: false,
    error: false,
    fields: [],
  },
  create: {
    result: false,
    message: '',
    conexion: false,
    error: false,
  },
  update: {
    result: false,
    message: '',
    conexion: false,
    error: false,
  },
  delete: {
    result: false,
    message: '',
    conexion: false,
    error: false,
  },

};

export default initState;
