import React from 'react';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { PropTypes } from 'prop-types';

export function FieldPhone({
  field, onChange, indexField, indexGroup,
}) {
  const handlerValidatePhone = (phone, onlyCountryies) => {
    let validate = false;
    validate = phone !== undefined ? matchIsValidTel(phone, { onlyCountryies }) : false;
    return validate;
  };

  return (
    <MuiTelInput
      fullWidth
      forceCallingCode
      value={field.value}
      onChange={(e) => { onChange(indexField, e, indexGroup); }}
      defaultCountry={field.validations.length > 0 && field.validations[0].length === 2 ? field.validations[0] : ''}
      onlyCountries={field.validations}
      size="small"
      error={field.required && !handlerValidatePhone(field.value, field.validations)}
      helperText={field.required && !handlerValidatePhone(field.value, field.validations) ? '* Campo Obligatorio' : ''}
      sx={{ mt: 2 }}
      disabled={field.hasOwnProperty('editable') ? !field.editable : false}
    />
  );
}

FieldPhone.propTypes = {
  field: PropTypes.objectOf.isRequired,
  onChange: PropTypes.func.isRequired,
  indexField: PropTypes.number.isRequired,
  indexGroup: PropTypes.number.isRequired,
};

export default FieldPhone;
