import { combineReducers } from 'redux';

// Reducers
import session from './session';
import usuario from './user';
import company from './company';
import application from './application';
import workflowStorage from './workFlow';
import storageBrowser from './storageBrowser';
import cards from './cards';
import master from './master';
import template from './template';
import coments from './coments';

const reducers = combineReducers({
  session,
  usuario,
  company,
  application,
  workflowStorage,
  storageBrowser,
  cards,
  master,
  template,
  coments,
});

export default reducers;
