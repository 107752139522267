import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Divider } from '@mui/material';
import bazooitLogo from '../../../assets/img/bazooitLogo.svg';
import './index.css';


const AppBarBazooit = ({ handStateMenu, handleCerrarSesion }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { firstName, lastName } = useSelector((store) => store.bazooit.session);
  const { connection } = useSelector((store) => store.bazooit.application)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar className='AppBar'>
      <Toolbar>
        <IconButton
          size="small"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => handStateMenu(true)}
          disabled={connection}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="span" sx={{ flexGrow: 1, mt:.5 }}>
            <span className='logo'><img src={bazooitLogo} alt="svg" /></span>
        </Typography>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem sx={{mt:-1, mb:-1, p:2}} onClick={handleClose}>{`${firstName} ${lastName}`}</MenuItem>
              <Divider />
              <MenuItem sx={{mt:-1, mb:-1, p:2}} >My Perfil</MenuItem>
              <Divider />
              <MenuItem sx={{mt:-1, mb:-1, p:2}} onClick={() => handleCerrarSesion()}>Salir</MenuItem>
            </Menu>
          </div>
      </Toolbar>
    </AppBar>
  );
}

export default AppBarBazooit;