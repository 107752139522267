const initState = {
  workflows: [],
  workflowTypes: [
    {
      id: 1,
      tipo: 'Libre',
      descripcion: 'Puede gestionar de forma libre sus caso de trabajo',
    },
    {
      id: 2,
      tipo: 'Dinámico',
      descripcion: 'Puede definir a donde avanza el caso y las tareas que debe haber cumplido.',
    },
    {
      id: 3,
      tipo: 'Estricto',
      descripcion: 'Puede definir los criterios mínimos para que un caso pase a la siguiente etapa',
    },
  ],
  message: '',
  connection: false,
  error: false,
  createWorkflowsGroup: {
    create: false,
    message: '',
    connection: false,
    error: false,
  },
  createWorkflow: {
    create: false,
    message: '',
    connection: false,
    error: false,
  },
  modifyWorkflows: {
    modificado: false,
    message: '',
    connection: false,
    error: false,
  },
};

export default initState;
